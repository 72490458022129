import { Amplify, Auth } from 'aws-amplify';
import { LoginResponseType } from '../../types';

// 使用するユーザープール、クライアントの ID を指定
Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
  }
});

export const signIn = async (email: string, password: string): Promise<LoginResponseType> => {
  try {
    const user = await Auth.signIn(email, password);
    console.log(user);
    return {
      statusCode: 200,
      message: 'ログインに成功しました',
      mailAddress: user.attributes.email,
      accessToken: user.signInUserSession.accessToken.jwtToken
    };
  } catch (error) {
    console.log(error);
    return {
      statusCode: 400,
      message: 'ログインに失敗しました',
      mailAddress: '',
      accessToken: ''
    };
  }
};
