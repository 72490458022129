import React, { forwardRef, useContext, useState } from 'react';
import classNames from 'classnames';

import { Handle, Remove } from '../Item';
import { isLogin, showToast, updateCategory, isImporterOrDK } from '../../../../Func';
import { PortfolioType, TabTypes } from '../../../../types';
import { LoginUserContext } from '../../../../provider/loginUserContext';
import { TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import styles from './Container.module.scss';

export interface Props {
  isEditable: boolean;
  cookies: any;
  removeCookie: (name: 'accessToken', value: any, options?: any) => void;
  tabType: TabTypes;

  children: React.ReactNode;
  columns?: number;
  label?: string;
  style?: React.CSSProperties;
  horizontal?: boolean;
  hover?: boolean;
  handleProps?: React.HTMLAttributes<any>;
  scrollable?: boolean;
  shadow?: boolean;
  placeholder?: boolean;
  unstyled?: boolean;

  portfolio: PortfolioType;
  setPortfolio: React.Dispatch<React.SetStateAction<PortfolioType>>;

  isChartDisabled: boolean;
  isKanbanView: boolean;

  containerId: number;

  onClick?(): void;
  onRemove?(): void;
}

/**
 * 1つ当たりの大枠の入れ物のコンテナ
 */
export const Container = forwardRef<HTMLDivElement, Props>(
  (
    {
      isEditable,
      cookies,
      removeCookie,
      tabType,
      children,
      columns = 1, // ここに来るnumberにより1行あたりの列数が決まる
      handleProps,
      horizontal,
      hover,
      onClick,
      onRemove,
      label, // カテゴリ名
      placeholder,
      style,
      scrollable,
      shadow,
      unstyled,
      portfolio,
      setPortfolio,
      isChartDisabled,
      isKanbanView,
      containerId,
      ...props
    }: Props,
    ref
  ) => {
    // TODO Componentを使わずに無理やりdivで動くように修正
    // const Component = onClick ? 'button' : 'div';
    const { user } = useContext(LoginUserContext);

    // テキストボックスのカテゴリ名
    const [categoryName, setCategoryName] = useState<string>(label || '');
    // カテゴリ名を適するにするかどうか
    const [isInput, setIsInput] = useState<boolean>(false);

    const updateCategoryName = async (accessToken: string, tCategoriesId: number) => {
      const res = await updateCategory(accessToken, tCategoriesId, categoryName);
      // もしトークン期限が切れている時
      if (res.body.message === 'Access Token has expired') {
        // トークン削除
        removeCookie('accessToken', '');
        // ログインの有効期限切れましたのトースト表示
        showToast('ログインの有効期限が切れました。', 'warning');
        // ここで処理終了
        return false;
      }
      if (res.statusCode === 500) {
        showToast('カテゴリーの名称変更に失敗しました', 'error');
        return false;
      }
      showToast('カテゴリーの名称変更に成功しました', 'success');
    };

    const updateCategoryContainer = async () => {
      // カテゴリ名が空の時はエラー
      if (categoryName === '') {
        showToast('カテゴリ名は必ず1文字以上で入力してくだい', 'error');
        setCategoryName(label || '');
        setIsInput(false);
        return;
      }
      // カテゴリ名変更のAPI呼び出し
      const accessToken = cookies['accessToken'] === undefined ? '' : cookies['accessToken'];
      const isDeletableRole = isImporterOrDK(user);
      // ログインユーザー、かつ編集可能なタブのときは名称更新API呼び出し
      if (isDeletableRole && isEditable) {
        // 非同期でずらして実行
        setTimeout(updateCategoryName, 1, accessToken, Number(containerId));
      }
      // ポートフォリオ更新
      const newCategories = portfolio.categories.map((c, index) => {
        const newCategoryName = c.t_categories_id === containerId ? categoryName : c.name;
        return {
          ...c,
          name: newCategoryName
        };
      });
      setPortfolio({ ...portfolio, categories: newCategories });
      setIsInput(false);
    };

    /**
     * カテゴリContainerの幅調整
     */
    let containerWidth: number | undefined = undefined;
    if (isKanbanView) {
      // カンバン表示
      if (isChartDisabled) {
        // チャート非表示
        if (columns === 1) {
          containerWidth = 400;
        }
        if (columns === 2) {
          containerWidth = 820;
        }
        if (columns === 3) {
          containerWidth = 1220;
        }
      } else {
        // チャート表示
        // チャート非表示
        if (columns === 1) {
          containerWidth = 900;
        }
        if (columns === 2) {
          containerWidth = 1900;
        }
        if (columns === 3) {
          containerWidth = 2800;
        }
      }
    } else {
      // 通常表示のときは何も設定せずでOK
    }

    return (
      <div
        {...props}
        ref={ref}
        style={
          {
            ...style,
            '--columns': columns,
            // カテゴリContainerの幅指定箇所
            width: containerWidth
          } as React.CSSProperties
        }
        className={classNames(
          styles.Container,
          unstyled && styles.unstyled,
          horizontal && styles.horizontal,
          hover && styles.hover,
          placeholder && styles.placeholder,
          scrollable && styles.scrollable,
          shadow && styles.shadow
        )}
        onClick={onClick}
        tabIndex={onClick ? 0 : undefined}
      >
        {label ? (
          // ヘッダー
          <div className={styles.Header}>
            {isInput ? (
              <TextField
                label='カテゴリの名称'
                variant='outlined'
                value={categoryName}
                autoFocus
                onChange={e => {
                  setCategoryName(e.target.value);
                }}
                onBlur={async () => {
                  // フォーカス外した段階でカテゴリ名保存
                  await updateCategoryContainer();
                }}
                onKeyDown={async e => {
                  // エンター押された時もカテゴリ名保存
                  if (e.key === 'Enter') {
                    await updateCategoryContainer();
                  }
                }}
              />
            ) : (
              // ログインユーザー、かつ編集可能なタブのときは名称変更可能
              <span
                onClick={() => isLogin(user) && isEditable && setIsInput(true)}
                style={{ cursor: isLogin(user) ? 'pointer' : '' }}
              >
                {label}
                {isLogin(user) && isEditable && <EditIcon style={{ height: 20, color: '#1976d2' }} />}
              </span>
            )}
            {/* カテゴリ名 */}
            {/* {label} */}
            <div className={styles.Actions}>
              {/* 銘柄追加ボタン */}
              {/* {onRemove ? (
                <Action
                  {...props}
                  active={{
                    fill: 'rgba(255, 70, 70, 0.95)',
                    background: 'rgba(255, 70, 70, 0.1)'
                  }}
                >
                  <svg width='8' viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M2.99998 -0.000206962C2.7441 -0.000206962 2.48794 0.0972617 2.29294 0.292762L0.292945 2.29276C-0.0980552 2.68376 -0.0980552 3.31682 0.292945 3.70682L7.58591 10.9998L0.292945 18.2928C-0.0980552 18.6838 -0.0980552 19.3168 0.292945 19.7068L2.29294 21.7068C2.68394 22.0978 3.31701 22.0978 3.70701 21.7068L11 14.4139L18.2929 21.7068C18.6829 22.0978 19.317 22.0978 19.707 21.7068L21.707 19.7068C22.098 19.3158 22.098 18.6828 21.707 18.2928L14.414 10.9998L21.707 3.70682C22.098 3.31682 22.098 2.68276 21.707 2.29276L19.707 0.292762C19.316 -0.0982383 18.6829 -0.0982383 18.2929 0.292762L11 7.58573L3.70701 0.292762C3.51151 0.0972617 3.25585 -0.000206962 2.99998 -0.000206962Z' />
                  </svg>
                </Action>
              ) : undefined} */}
              {/* ☓の削除ボタン、ログインユーザー、かつ編集可能なタブのとき */}
              {isLogin(user) && isEditable && onRemove ? <Remove onClick={onRemove} /> : undefined}
              {/* 掴むところ */}
              <Handle {...handleProps} />
            </div>
          </div>
        ) : null}
        {placeholder ? children : <ul>{children}</ul>}
      </div>
    );

    // return (
    //   <Component
    //     {...props}
    //     ref={ref}
    //     style={
    //       {
    //         ...style,
    //         '--columns': columns
    //       } as React.CSSProperties
    //     }
    //     className={classNames(
    //       styles.Container,
    //       unstyled && styles.unstyled,
    //       horizontal && styles.horizontal,
    //       hover && styles.hover,
    //       placeholder && styles.placeholder,
    //       scrollable && styles.scrollable,
    //       shadow && styles.shadow
    //     )}
    //     onClick={onClick}
    //     tabIndex={onClick ? 0 : undefined}
    //   >
    //     {label ? (
    //       <div className={styles.Header}>
    //         {label}
    //         <div className={styles.Actions}>
    //           {onRemove ? <Remove onClick={onRemove} /> : undefined}
    //           <Handle {...handleProps} />
    //         </div>
    //       </div>
    //     ) : null}
    //     {placeholder ? children : <ul>{children}</ul>}
    //   </Component>
    // );
  }
);
