import { Dispatch, SetStateAction } from 'react';

export type ToastType = 'error' | 'info' | 'success' | 'warning' | 'dark';

export type LoginUserContextType = {
  user: UserType;
  setUser: Dispatch<SetStateAction<UserType>>;
};
export interface UserType {
  mailAddress: string;
  accessToken: string;
  roles: RoleType[];
}

export type AllIssueContextType = {
  allIssuesDic: AllIssuesDic;
  setAllIssuesDic: Dispatch<SetStateAction<AllIssuesDic>>;
};

export type GlobalLoadingContextType = {
  isGlobalLoading: boolean;
  setIsGlobalLoading: Dispatch<SetStateAction<boolean>>;
};

export type RdsStatusContextType = {
  rdsStatus: RdsStatusEnum;
  setRdsStatus: Dispatch<SetStateAction<RdsStatusEnum>>;
};

export type MasterContextType = {
  master: MasterType;
  setMaster: Dispatch<SetStateAction<MasterType>>;
};

export enum RdsStatusEnum {
  // 起動中
  STARTING = 'starting',
  // 修正中(起動前の初期化？？)
  MODIFYING = 'modifying',
  // 起動完了
  AVAILABLE = 'available',
  // 停止中
  STOPPING = 'stopping',
  // 停止完了
  STOPPED = 'stopped'
}

export enum MarketDivisionsEnum {
  東P = 1,
  ET = 2,
  東S = 3,
  東G = 4,
  PR = 5,
  外P = 6,
  RE = 7,
  外S = 8,
  外G = 9,
  出資 = 10
}

// export enum MarketDivisionsEnum {
//   '1' = '東P',
//   ET = 2,
//   東S = 3,
//   東G = 4,
//   PR = 5,
//   外P = 6,
//   RE = 7,
//   外S = 8,
//   外G = 9,
//   出資 = 10
// }

/**
 * タブの種類
 */
export type TabTypes = 'categoryTabType' | 'categoryCanbanTabType' | 'industryClassTabType';

export interface AllIssuesDic {
  [stock_code: string]: IssueType;
}

export interface RoleType {
  name: string;
}

export interface LoginResponseType {
  statusCode: number;
  message: string;
  mailAddress: string;
  accessToken: string;
}

/**
 * 1つのカードの中のスタイル
 */
export interface CardStyleType {
  /** 1カードの横幅(※MultipleContainerStypeType側も変えないと変わらないので注意) */
  cardWidth: number;
  /** 1カードの縦幅(※MultipleContainerStypeType側も変えないと変わらないので注意) */
  cardHeight: number;
  hAxisShowTextEvery: number;
  /** チャートの縦幅、100%にするとX軸のタイトル(日付)が見えなくなる */
  chartAreaHeight: string;
  /** チャートの横幅、100%にするとY軸のタイトル(株価)が見えなくなる */
  chartAreaWidth: string;
  dailyChartsLength: number;
  googleChartWidth: number;
  googleChartHeight: number;
  cardTitleAreaWidth: number;
}

/**
 * MultipleContainerのスタイル(カードの大枠指定込)
 */
export interface MultipleContainerStyleType {
  /** 1行あたりの表示カード数 */
  colomns: number;
  /** 1カードの横幅 */
  cardWidth: number;
  /** 1カードの縦幅(※いくらグラフの高さを変えてもここの大元を変えないと変わらないので注意) */
  cardHeight: number;
  /** 1カードごとの右の隙間 */
  cardMarginRight: number;
  /** カテゴリを1列に表示するか */
  vertical: boolean;
}

export interface MasterType {
  industry_classifications: IndustryClassificationType[];
  market_divisions: MarketDivisionType[];
}
export interface IndustryClassificationType {
  m_industry_classifications_id: number;
  name: string;
}
export interface MarketDivisionType {
  m_market_divisions_id: number;
  name: string;
}

export interface PortfolioType {
  user_id: number;
  categories: CategoryType[];
}

export interface CategoryType {
  t_categories_id: number;
  name: string;
  index: number;
  stock_codes: number[];
}

// TODO：本当はIssueTypeと統合したい、、、
/**
 * 企業情報タイプ
 */
export interface CompanyType {
  name: string;
  stock_code: number;
  url: string;
  is_import: boolean;
  m_market_divisions_id: number;
  m_industry_classifications_id: number;
  updated_at: string;
  isLoading: boolean;
}

export interface IssueType {
  name: string;
  stock_code: number;
  url: string;
  is_import: boolean;
  m_market_divisions_id: number;
  m_industry_classifications_id: number;
  updated_at: string;
  isLoading: boolean;
  chart: ChartType;
}

export interface ChartType {
  stock_code: string;
  // average_moving_line_seventyfive: number[];
  daily_charts: DailyChartType[];
}

export interface DailyChartType {
  close: number;
  datetime: string;
  high: number;
  low: number;
  open: number;
  volume: number;
  average_moving_line_five_value: number;
  average_moving_line_twentyfive_value: number;
  average_moving_line_seventyfive_value: number;
  bollinger_bands_plus_two_alpha: number;
  bollinger_bands_minus_two_alpha: number;
}

export interface GetRdsStatusResponseType {
  statusCode: number;
  body: GetRdsStatusBodyResponseType;
}
export interface GetRdsStatusBodyResponseType {
  message: string;
}

export interface ChangeRdsStatusResponseType {
  statusCode: number;
  body: ChangeRdsStatusBodyResponseType;
}
export interface ChangeRdsStatusBodyResponseType {
  message: string;
}

export interface GetMasterResponseType {
  statusCode: number;
  body: GetMasterBodyResponseType;
}
export interface GetMasterBodyResponseType {
  response: MasterType;
  message: string;
}

export interface GetIssuesResponseType {
  statusCode: number;
  body: GetIssuesBodyResponseType;
}
export interface GetIssuesBodyResponseType {
  response: IssueType[];
  message: string;
}

export interface ImportIssueResponseType {
  statusCode: number;
  body: ImportAssertionsIssueBodyResponseType;
}
export interface ImportAssertionsIssueBodyResponseType {
  issue_list: IssueType[];
  message: string;
}

export interface GetAllIssuesResponseType {
  statusCode: number;
  body: GetAllIssuesBodyResponseType;
}
export interface GetAllIssuesBodyResponseType {
  company_list: CompanyType[];
  message: string;
}

export interface GetPortfolioResponseType {
  statusCode: number;
  body: GetPortfolioBodyResponseType;
}
export interface GetPortfolioBodyResponseType {
  portfolio: PortfolioType;
  issue_list: IssueType[];
  message: string;
}

export interface GetUserResponseType {
  statusCode: number;
  body: GetUserBodyResponseType;
}
export interface GetUserBodyResponseType {
  user_id: number;
  mail_address: string;
  roles: RoleType[];
  message: string;
}

export interface AddIssueInCategoryResponseType {
  statusCode: number;
  body: AddIssueInCategoryBodyResponseType;
}
export interface AddIssueInCategoryBodyResponseType {
  issue_list: IssueType[];
  l_categories_companies_id: number;
  message: string;
}

export interface SortIssueInCategoryResponseType {
  statusCode: number;
  body: SortIssueInCategoryBodyResponseType;
}
export interface SortIssueInCategoryBodyResponseType {
  message: string;
}

export interface AddCategoryResponseType {
  statusCode: number;
  body: AddCategoryBodyResponseType;
}
export interface AddCategoryBodyResponseType {
  message: string;
  tCategoriesId: number;
  index: number;
}

export interface DeleteCategoryResponseType {
  statusCode: number;
  body: DeleteCategoryBodyResponseType;
}
export interface DeleteCategoryBodyResponseType {
  message: string;
}

export interface UpdateCategoryResponseType {
  statusCode: number;
  body: UpdateCategoryBodyResponseType;
}
export interface UpdateCategoryBodyResponseType {
  message: string;
}

export interface SortCategoryResponseType {
  statusCode: number;
  body: SortCategoryBodyResponseType;
}
export interface SortCategoryBodyResponseType {
  message: string;
  tCategories: number[];
}
