// import './css/index.css';
import { CookiesProvider } from 'react-cookie';
import { useCookies } from 'react-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { IssueType, UserType, AllIssuesDic, CompanyType, RdsStatusEnum, MasterType } from './types';
import { useState, useEffect } from 'react';
import { LoginUserContext } from './provider/loginUserContext';
import { AllIssuesContext } from './provider/nekoContext';
import { getUser, getIssues, getAllIssues, getRdsStatus, isRdsStatusAvailable, getMaster } from './Func';
import { splitList } from './utils/splitList';
import TopPage from './pages/TopPage';
import { GlobalLoadingContext } from './provider/globalLoadingContext';
import { RdsStatusContext } from './provider/rdsStatusContext';
import { MasterContext } from './provider/masterContext';

/**
 * 銘柄を同時に取得する件数
 */
const GET_ISSUE_COUNT = 5;

const App = () => {
  // グローバルローディングstate、初期値はクルクルあり
  const [isGlobalLoading, setIsGlobalLoading] = useState<boolean>(true);
  // RDSステータスstate
  const [rdsStatus, setRdsStatus] = useState<RdsStatusEnum>(RdsStatusEnum.STARTING);
  // マスターstate
  const [master, setMaster] = useState<MasterType>({ industry_classifications: [], market_divisions: [] });
  // クッキー生成
  const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
  // ログインユーザーstate
  const [user, setUser] = useState<UserType>({ mailAddress: '', accessToken: '', roles: [] });
  // 全銘柄state
  const [allIssuesDic, setAllIssuesDic] = useState<AllIssuesDic>({});
  useEffect(() => {
    const getPortfolioAndAllIssuesEffect = async () => {
      // RDSステータス取得
      const rdsStatusRes = await getRdsStatus();
      setRdsStatus(rdsStatusRes.body.message as RdsStatusEnum);
      const isAvailable = isRdsStatusAvailable(rdsStatusRes.body.message as RdsStatusEnum);
      // RDSが起動完了状態でないときはローディング終了してここで処理終了
      if (!isAvailable) {
        setIsGlobalLoading(false);
        return;
      }
      // マスタ取得
      const masterRes = await getMaster();
      setMaster(masterRes.body.response);
      // ログインユーザー情報取得
      const res = await getUser(cookies.accessToken);
      if (res.user_id !== 0) {
        // ログインしている、かつ有効期限が残っているとき
        setUser({ mailAddress: res.mail_address, accessToken: cookies.accessToken, roles: res.roles });
      } else {
        // ログインしていない、または有効期限が切れているとき
        setUser({ mailAddress: '', accessToken: '', roles: [] });
        removeCookie('accessToken');
      }
      // 全銘柄取得(チャートなし)
      const getAllIssuesRes = await getAllIssues();
      if (getAllIssuesRes.statusCode === 200) {
        // 全銘柄を辞書型に変換
        let issuesDic: AllIssuesDic = {};
        getAllIssuesRes.body.company_list.forEach((company: CompanyType) => {
          // 銘柄コードがKeyのオブジェクト生成(チャートなし)
          issuesDic[company.stock_code] = {
            ...company,
            chart: { stock_code: String(company.stock_code), daily_charts: [] }
          };
        });
        // 既にチャート取り込み済みの銘柄のみ抽出
        const importedIssues = getAllIssuesRes.body.company_list.filter((company: CompanyType) => {
          return company.is_import;
        });
        // 5件ずつの銘柄のリストに変換
        const splittedImportedIssues = splitList(importedIssues, GET_ISSUE_COUNT) as IssueType[][];
        // 5件ずつのリストループ
        for (const issuesList of splittedImportedIssues) {
          // 5件取得
          const getIssuesRes = await getIssues(issuesList.map(issue => String(issue.stock_code)));
          // 5件の取得に成功した時
          if (getIssuesRes.statusCode === 200) {
            getIssuesRes.body.response.forEach((issue: IssueType) => {
              // 全チャートContextにセット
              issuesDic[`${issue.stock_code}`] = issue;
            });
          }
        }
        // 全銘柄Contextにセット
        setAllIssuesDic(issuesDic);
        // グローバルローディング終了
        setIsGlobalLoading(false);
      }
    };
    getPortfolioAndAllIssuesEffect();
    // この行だけeslint-disable-next-lineをつけてwarningを消す
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <GlobalLoadingContext.Provider value={{ isGlobalLoading, setIsGlobalLoading }}>
      <RdsStatusContext.Provider value={{ rdsStatus, setRdsStatus }}>
        <MasterContext.Provider value={{ master, setMaster }}>
          <CookiesProvider>
            <LoginUserContext.Provider value={{ user, setUser }}>
              <AllIssuesContext.Provider value={{ allIssuesDic, setAllIssuesDic }}>
                <TopPage cookies={cookies} setCookie={setCookie} removeCookie={removeCookie} />
              </AllIssuesContext.Provider>
            </LoginUserContext.Provider>
          </CookiesProvider>
        </MasterContext.Provider>
      </RdsStatusContext.Provider>
    </GlobalLoadingContext.Provider>
  );
};

export default App;
