import { Fragment, cloneElement, useContext, ReactElement, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import LoginModal from '../component/modal/LoginModal';
import ChangeRdsStatusModals from '../component/modal/ChangeRdsStatusModal';
import 'react-toastify/dist/ReactToastify.css';
import { CookieSetOptions } from 'universal-cookie';
import { Button } from '@mui/material';
import { UniqueIdentifier } from '@dnd-kit/core';
import { Items } from '../component/dnd-kit/examples/Sortable/MultipleContainers';
import { LoginUserContext } from '../provider/loginUserContext';
import { AllIssuesContext } from '../provider/nekoContext';
import {
  isLogin,
  addIssueInCategory,
  showToast,
  importIssue,
  getPortfolio,
  isRdsStatusAvailable,
  isDK,
  changeRdsStatus
} from '../Func';
import { AllIssuesDic, UserType, PortfolioType, CategoryType, IssueType, RdsStatusEnum } from '../types';
import { fromDateToFullYearMonthDate } from '../utils/dateFormatter';
import { randomString } from '../utils/randomString';
import { GlobalLoadingContext } from '../provider/globalLoadingContext';
import { RdsStatusContext } from '../provider/rdsStatusContext';
import OptionAreaTemplate from '../template/OptionAreaTemplate';

interface Props {
  window?: () => Window; // 何か使われているっぽい
  portfolio: PortfolioType;
  setPortfolio: React.Dispatch<React.SetStateAction<PortfolioType>>;
  setItems: React.Dispatch<React.SetStateAction<Items>>;
  setContainers: React.Dispatch<React.SetStateAction<UniqueIdentifier[]>>;
  children: ReactElement;
  cookies: any;
  setCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  removeCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  handleChange: (event: React.SyntheticEvent, newValue: string) => void;
  // OptionAreaTemplateProps
  isSumaho: boolean;
  selectableIssues: IssueType[];
  setSelectableIssues: React.Dispatch<React.SetStateAction<IssueType[]>>;
  columnCount: { value: number; label: string };
  setColumnCount: React.Dispatch<React.SetStateAction<{ value: number; label: string }>>;
  isChartDisabled: boolean;
  setIsChartDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isKanbanView: boolean;
  setIsKanbanView: React.Dispatch<React.SetStateAction<boolean>>;
}

function ElevationScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
}

/**
 * 銘柄を取り込む件数
 */
const IMPORT_COUNT = 5;

/**
 * まだカテゴリーに追加されていない銘柄を5件、1つめのカテゴリーに追加する
 * @param mIndustryClassificationsId
 * @param allIssuesDic
 * @param user
 * @param portfolio
 * @param setPortfolio
 */
const addIssueInFirstCategory = async (
  mIndustryClassificationsId: number,
  allIssuesDic: AllIssuesDic,
  user: UserType,
  portfolio: PortfolioType,
  setPortfolio: React.Dispatch<React.SetStateAction<PortfolioType>>,
  setItems: React.Dispatch<React.SetStateAction<Items>>,
  setContainers: React.Dispatch<React.SetStateAction<UniqueIdentifier[]>>
) => {
  // 鉄鋼のリストを生成(鉄鋼)
  let targetStockCodes: string[] = [];
  for (const stockCode of Object.keys(allIssuesDic)) {
    if (allIssuesDic[stockCode].m_industry_classifications_id === mIndustryClassificationsId) {
      targetStockCodes.push(stockCode);
    }
  }
  // 更新対象の1つ目のカテゴリー取得
  const tCategoriesId = portfolio.categories[0].t_categories_id;
  let loopCount = 0;
  for (const stockCode of targetStockCodes) {
    let isInCategory = false;
    if (loopCount < IMPORT_COUNT) {
      // 既にカテゴリーに入っている銘柄かチェック
      for (const category of portfolio.categories) {
        for (const sc of category.stock_codes) {
          if (sc === Number(stockCode)) {
            isInCategory = true;
            break;
          }
        }
      }
      // 既に入っている場合は次の銘柄チェック
      if (isInCategory) {
        continue;
      }
      // yahoofinanceAPI2呼び出し用のUserAgentを3文字のランダム文字列で生成
      const userAgent = randomString(3);
      // まだカテゴリーに入っていない場合は1つ目のカテゴリーに追加
      const res = await addIssueInCategory(
        user.accessToken,
        tCategoriesId, // 1つめのカテゴリーに追加
        Number(stockCode),
        allIssuesDic[stockCode].is_import,
        userAgent
      );
      // 取り込み成功の場合
      if (res.statusCode === 200) {
        // とりこみがまだであれば最新のチャートを全銘柄Dicにセット
        if (!allIssuesDic[stockCode].is_import) {
          allIssuesDic[stockCode] = res.body.issue_list[0];
        }
        // 更新対象のカテゴリー取得して銘柄コードをカテゴリーの末尾に追加
        const targetCategory = portfolio.categories.find(
          c => c.t_categories_id === Number(tCategoriesId)
        ) as CategoryType;
        targetCategory.stock_codes.push(Number(stockCode));
        // 元のstateからまるっと新しいカテゴリーのstateのリストを作成
        const newCategories = portfolio.categories.map(c => {
          if (c.t_categories_id === Number(tCategoriesId)) {
            return targetCategory;
          } else {
            return c;
          }
        });
        const newPortfolio = { ...portfolio, categories: newCategories };
        setPortfolio(newPortfolio);
        // dnd-kitのContainerとItem更新
        let itemsObj: Items = {};
        // カテゴリ単位のKeyのプロパティに銘柄コードのリストをセット
        newPortfolio.categories.forEach((c: CategoryType, index: number) => {
          Object.assign(itemsObj, { [`${c.t_categories_id}`]: [...c.stock_codes] });
        });
        setItems(itemsObj);
        // カテゴリーのKeyのリストをセット
        const containerKeys = newPortfolio.categories.map(c => c.t_categories_id as UniqueIdentifier);
        setContainers(containerKeys);
      } else {
        showToast('取り込みに失敗しましたしました', 'error');
        break;
      }
      console.log('カウントアップ');
      console.log(allIssuesDic[stockCode]);
      loopCount++;
    } else {
      // 6回目でループ終了
      showToast('最新5件の銘柄取り込みに成功しました', 'success');
      console.log('ループ終了');
      break;
    }
  }
};

/**
 * カテゴリに含まれている全銘柄を最新化
 * 注意！！！更新日が今日になっている銘柄は取り込まれない仕様になっている
 * @param allIssuesDic
 * @param setAllIssuesDic
 * @param portfolio
 * @param setPortfolio
 * @param user
 * @param removeCookie
 * @param setIsGlobalLoading
 */
const updateChart = async (
  allIssuesDic: AllIssuesDic,
  setAllIssuesDic: React.Dispatch<React.SetStateAction<AllIssuesDic>>,
  portfolio: PortfolioType,
  setPortfolio: React.Dispatch<React.SetStateAction<PortfolioType>>,
  user: UserType,
  removeCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void,
  setIsGlobalLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  // グローバルクルクル表示
  setIsGlobalLoading(true);
  // 今日の日付取得
  const today = fromDateToFullYearMonthDate(new Date());
  // 既にカテゴリーに入っている銘柄かチェック
  // const categoryStockCodeList = portfolio.categories.flatMap(c => c.stock_codes);
  // アプリ全体で取り込んでいる銘柄コード取得
  const allStockCodeList = Object.keys(allIssuesDic)
    .map(strStockCode => Number(strStockCode))
    .filter(code => allIssuesDic[String(code)].is_import);
  let newAllIssyeDic = { ...allIssuesDic };
  // yahoofinanceAPI2呼び出し用のUserAgentを3文字のランダム文字列で生成
  const userAgent = randomString(3);
  // カテゴリーに入っている銘柄ループ
  for (const stockCode of allStockCodeList) {
    // 最新の更新日取得
    const portFolioDate = fromDateToFullYearMonthDate(new Date(allIssuesDic[stockCode].updated_at));
    // 今日まだ取り込んでいなければ取り込み
    if (portFolioDate < today) {
      const res = await importIssue(user.accessToken, stockCode, userAgent);
      if (res.statusCode === 200) {
        // 取り込みチャートを抽出
        const importIssue = res.body.issue_list.find(issue => issue.stock_code === stockCode) as IssueType;
        // 全チャートContextにセット
        newAllIssyeDic[`${stockCode}`] = importIssue;
      } else {
        if (res.body.message === 'Access Token has expired') {
          // ポートフォリオ再取得
          const repeatRes = await getPortfolio('', false);
          // ポートフォリオのstate更新
          setPortfolio(repeatRes.body.portfolio);
          // トークン削除
          removeCookie('accessToken', '');
          // ログインの有効期限切れましたのトースト表示
          showToast('ログインの有効期限が切れました。', 'warning');
        } else {
          showToast('チャートの最新化に失敗しました', 'error');
        }
      }
    } else {
      // TODO：ここのelseの分岐は動作確認でき次第、削除する
      console.log('取り込みができているかは明日のお楽しみ、下は最新で取り込まれなかった銘柄');
      console.log(stockCode);
    }
  }
  setAllIssuesDic(newAllIssyeDic);
  showToast('最新チャートの取り込みに成功しました', 'success');
  // グローバルクルクル解除
  setIsGlobalLoading(false);
};

/**
 * コンポーネント本体
 * @param props
 * @returns
 */
export default function AppBarTemplate(props: Props) {
  const {
    handleChange,
    selectableIssues,
    setSelectableIssues,
    columnCount,
    setColumnCount,
    isChartDisabled,
    setIsChartDisabled,
    isKanbanView,
    setIsKanbanView
  } = props;
  // ログインユーザーcontext
  const { user } = useContext(LoginUserContext);
  const { allIssuesDic, setAllIssuesDic } = useContext(AllIssuesContext);
  const { setIsGlobalLoading } = useContext(GlobalLoadingContext);
  const { rdsStatus, setRdsStatus } = useContext(RdsStatusContext);

  // スマホ判定横幅Width
  const SUMAHO_WIDTH = 430;
  // 画面サイズの取得
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  };
  const [, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar>
          <Toolbar>
            <Typography variant='h6' component='div' sx={{ flexGrow: 2 }}>
              UK Chart
            </Typography>
            <LoginModal
              cookies={props.cookies}
              setCookie={props.setCookie}
              removeCookie={props.removeCookie}
            />
            {isLogin(user) ? (
              // 非表示にしているのでそもそも見えない！！！
              // ログインしている時
              <Button
                onClick={() => {
                  addIssueInFirstCategory(
                    3450,
                    allIssuesDic,
                    user,
                    props.portfolio,
                    props.setPortfolio,
                    props.setItems,
                    props.setContainers
                  );
                }}
                color='secondary'
                variant='contained'
                style={{ display: 'none' }}
              >
                鉄鋼取り込み
              </Button>
            ) : (
              // ログインしていないとき
              <></>
            )}
            {isLogin(user) ? (
              // ログインしている時
              <Button
                onClick={() => {
                  updateChart(
                    allIssuesDic,
                    setAllIssuesDic,
                    props.portfolio,
                    props.setPortfolio,
                    user,
                    props.removeCookie,
                    setIsGlobalLoading
                  );
                }}
                color='warning'
                variant='contained'
                style={{ marginLeft: 10 }}
              >
                最新化
              </Button>
            ) : (
              // ログインしていないとき
              <></>
            )}
            {/* RDS起動ステータスが起動完了でないときは起動ボタン表示 */}
            {!isRdsStatusAvailable(rdsStatus) ? (
              <ChangeRdsStatusModals />
            ) : // DKの時はRDS停止ボタン表示
            isDK(user) ? (
              <Button
                onClick={async () => {
                  // RDS停止確認ダイアログ表示
                  if (window.confirm('RDSを停止しますか？')) {
                    // RDS停止リクエスト送信
                    const res = await changeRdsStatus(user.accessToken, false);
                    if (res.statusCode === 200) {
                      // RDS起動ステータスContextを起動中に変更
                      setRdsStatus(RdsStatusEnum.STOPPING);
                      showToast('RDS停止リクエストを送りました', 'success');
                    } else {
                      if (res.body.message === 'Access Token has expired') {
                        // ポートフォリオ再取得
                        const repeatRes = await getPortfolio('', false);
                        // ポートフォリオのstate更新
                        props.setPortfolio(repeatRes.body.portfolio);
                        // トークン削除
                        props.removeCookie('accessToken', '');
                        // ログインの有効期限切れましたのトースト表示
                        showToast('ログインの有効期限が切れました。', 'warning');
                      } else {
                        showToast('RDSの停止に失敗しました', 'error');
                      }
                    }
                  }
                }}
                color='secondary'
                variant='contained'
                style={{ marginLeft: 10 }}
              >
                RDS停止
              </Button>
            ) : (
              <></>
            )}
          </Toolbar>
          {/* タブ */}
          <Box sx={{ borderColor: 'divider', backgroundColor: '#F5F5F5' }}>
            <TabList onChange={handleChange} aria-label='lab API tabs example'>
              <Tab label='カテゴリ別' value='1' />
              <Tab label='業種別' value='2' />
            </TabList>
          </Box>
          {/* カテゴリ・銘柄設定行 */}
          <OptionAreaTemplate
            isSumaho={getWindowDimensions().width < SUMAHO_WIDTH}
            selectableIssues={selectableIssues}
            setSelectableIssues={setSelectableIssues}
            columnCount={columnCount}
            setColumnCount={setColumnCount}
            isChartDisabled={isChartDisabled}
            setIsChartDisabled={setIsChartDisabled}
            isKanbanView={isKanbanView}
            setIsKanbanView={setIsKanbanView}
            cookies={props.cookies}
            setCookie={props.setCookie}
            removeCookie={props.removeCookie}
            portfolio={props.portfolio}
            setPortfolio={props.setPortfolio}
            setItems={props.setItems}
            setContainers={props.setContainers}
          />
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </Fragment>
  );
}
