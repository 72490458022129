import { FC, useContext } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Chart from '../chart/Chart';
import 'react-toastify/dist/ReactToastify.css';
import { AllIssuesContext } from '../../provider/nekoContext';
import { setMarketDivisionsColor } from '../../utils/setMarketDivisionsColor';
import Avatar from '@mui/material/Avatar';
import Volume from '../volume/Volume';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

interface ZoomModalProps {
  selectedStockCode: number;
  isZoomModalOpen: boolean;
  setIsZoomModalOpen: (isZoomModalShow: boolean) => void;
}

const ZoomModal: FC<{
  selectedStockCode: number;
  isZoomModalOpen: boolean;
  setIsZoomModalOpen: (isZoomModalShow: boolean) => void;
}> = (props: ZoomModalProps) => {
  const { selectedStockCode, isZoomModalOpen, setIsZoomModalOpen } = props;
  const handleClose = () => setIsZoomModalOpen(false);
  const { allIssuesDic } = useContext(AllIssuesContext);
  const selectedIssue = allIssuesDic[String(selectedStockCode)];
  const cardStyle = {
    cardWidth: 3000,
    cardHeight: 800,
    hAxisShowTextEvery: 13,
    chartAreaHeight: '85%',
    chartAreaWidth: '90%',
    dailyChartsLength: 170,
    googleChartWidth: 3000,
    googleChartHeight: 800,
    cardTitleAreaWidth: 3000
  };

  // 市場区分の色
  const marketDivision = setMarketDivisionsColor(selectedIssue.m_market_divisions_id);

  return (
    <Modal
      open={isZoomModalOpen}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Typography id='modal-modal-title' variant='h6' component='h2'>
          <Avatar
            sx={{
              width: 25,
              height: 25,
              fontSize: 12,
              float: 'left',
              backgroundColor: marketDivision.marketDivisionsColor
            }}
          >
            {marketDivision.marketDivisionsName}
          </Avatar>
          {`${selectedIssue.stock_code}：${selectedIssue.name}`}
        </Typography>
        <div style={{ overflow: 'scroll', height: '100%' }}>
          <Chart
            isLoading={false}
            name={selectedIssue.name}
            stock_code={selectedStockCode}
            url={selectedIssue.url}
            chart={selectedIssue.chart}
            cardStyle={cardStyle}
            tabType={'categoryTabType'}
            isChartDisabled={true}
          />
          <Volume chart={selectedIssue.chart} cardStyle={cardStyle} />
        </div>
      </Box>
    </Modal>
  );
};

export default ZoomModal;
