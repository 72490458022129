/**
 * 指定した文字数のランダムな文字列を生成する
 * @param length
 * @returns
 */
export const randomString = (length: number): string => {
  let string = '';

  for (let i = 1; i <= length; i++) {
    string += randomSpell();
  }

  return string;
};
const randomSpell = () => {
  const spells =
    '0,1,2,3,4,5,6,7,8,9,' +
    'a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z,' +
    'A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z';

  const spellArray = spells.split(',');
  // spellArray = ['0', '1', '2', ... 'a', 'b', 'c', ... 'X', 'Y', 'Z']

  // Arrayの要素数
  const spellLength = spellArray.length;

  // Arrayの要素数-1 を最大値とした乱数
  const index = randomInteger(spellLength - 1);

  // 乱数 番目の文字を取得
  const spell = spellArray[index];

  return spell;
};
const randomInteger = (max: number): number => {
  const num = Math.random() * (max + 1);
  const numInt = Math.trunc(num);
  return numInt;
};
