/**
 * リストを一定の個数で分割する
 * chunk([1, 2, 3, 4, 5, 6, 7], 3); // -> [[1, 2, 3], [4, 5, 6], [7]]
 * @param arr
 * @param size
 * @returns
 */
export const splitList = <T extends any[]>(arr: T, size: number) => {
  return arr.reduce((newarr, _, i) => (i % size ? newarr : [...newarr, arr.slice(i, i + size)]), [] as T[][]);
};
