import { FC, useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { CategoryType, IssueType, PortfolioType } from '../types';
import { getPortfolio, showToast, isLogin, isRdsStatusAvailable } from '../Func';
import { CookieSetOptions } from 'universal-cookie';
import { Items } from '../component/dnd-kit/examples/Sortable/MultipleContainers';
import { UniqueIdentifier } from '@dnd-kit/core';
import { LoginUserContext } from '../provider/loginUserContext';
import AddCategoryModal from '../component/modal/AddCategoryModal';
import AddIssueModal from '../component/modal/AddIssueModal';
import { RdsStatusContext } from '../provider/rdsStatusContext';
import { FormControlLabel, Switch } from '@mui/material';

interface OptionAreaTemplateProps {
  isSumaho: boolean;
  cookies: any;
  setCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  removeCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  portfolio: PortfolioType;
  setPortfolio: React.Dispatch<React.SetStateAction<PortfolioType>>;
  selectableIssues: IssueType[];
  setSelectableIssues: React.Dispatch<React.SetStateAction<IssueType[]>>;
  setItems: React.Dispatch<React.SetStateAction<Items>>;
  setContainers: React.Dispatch<React.SetStateAction<UniqueIdentifier[]>>;
  columnCount: { value: number; label: string };
  setColumnCount: React.Dispatch<React.SetStateAction<{ value: number; label: string }>>;
  isChartDisabled: boolean;
  setIsChartDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isKanbanView: boolean;
  setIsKanbanView: React.Dispatch<React.SetStateAction<boolean>>;
}

const OptionAreaTemplate: FC<{
  isSumaho: boolean;
  cookies: any;
  setCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  removeCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  portfolio: PortfolioType;
  setPortfolio: React.Dispatch<React.SetStateAction<PortfolioType>>;
  selectableIssues: IssueType[];
  setSelectableIssues: React.Dispatch<React.SetStateAction<IssueType[]>>;
  setItems: React.Dispatch<React.SetStateAction<Items>>;
  setContainers: React.Dispatch<React.SetStateAction<UniqueIdentifier[]>>;
  columnCount: { value: number; label: string };
  setColumnCount: React.Dispatch<React.SetStateAction<{ value: number; label: string }>>;
  isChartDisabled: boolean;
  setIsChartDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isKanbanView: boolean;
  setIsKanbanView: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props: OptionAreaTemplateProps) => {
  const [, setIssues] = useState<IssueType[]>([]);
  const [, setCategories] = useState<CategoryType[]>([]);

  // コンポーネントのobjのstete
  const [, setItems] = useState<Items>({});
  // コンポーネントのリストのstate
  const [, setContainers] = useState<UniqueIdentifier[]>([]);

  const { user } = useContext(LoginUserContext);

  const { rdsStatus } = useContext(RdsStatusContext);

  // 初回のみ株データ取得
  useEffect(() => {
    const getPortfolioEffect = async () => {
      // RDSのステータスが利用可能でない時
      if (!isRdsStatusAvailable(rdsStatus)) {
        return false;
      }
      // cookieからaccessToken取得
      const accessToken = props.cookies['accessToken'] === undefined ? '' : props.cookies['accessToken'];
      // ユーザーのポートフォリオ取得
      const res = await getPortfolio(accessToken, false);
      // もしトークン期限が切れている時
      if (res.body.message === 'Access Token has expired') {
        // ポートフォリオ再取得
        const repeatRes = await getPortfolio('', false);
        // トークン削除
        props.removeCookie('accessToken', '');
        // ログインの有効期限切れましたのトースト表示
        showToast('ログインの有効期限が切れました。', 'warning');
        return repeatRes.body;
      }
      return res.body;
    };
    getPortfolioEffect().then(getPortfolioResBody => {
      //RDSのステータスが利用可能でない時はここで処理終了
      if (getPortfolioResBody === false) return;
      setIssues(getPortfolioResBody.issue_list);
      setCategories(getPortfolioResBody.portfolio.categories);
      let itemsObj: Items = {};
      getPortfolioResBody.portfolio.categories.forEach((c: CategoryType, index: number) => {
        Object.assign(itemsObj, { [`${c.name}`]: [...c.stock_codes] });
      });
      const containerKeys = getPortfolioResBody.portfolio.categories.map(
        c => c.t_categories_id as UniqueIdentifier
      );
      setItems(itemsObj);
      setContainers(containerKeys);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cookies['accessToken']]);

  return (
    <div
      style={{
        textAlign: 'right',
        backgroundColor: 'white',
        paddingRight: 30,
        paddingTop: 10,
        paddingBottom: 5
      }}
    >
      <span style={{ marginRight: 10, color: 'black' }}>1行あたりの銘柄数</span>
      <span style={{ display: 'inline-flex' }}>
        <div style={{ width: 100, color: 'black' }}>
          <Select
            options={[
              { value: 1, label: '1' },
              { value: 2, label: '2' },
              { value: 3, label: '3' }
            ]}
            value={props.columnCount}
            onChange={e => {
              props.setColumnCount(e as { value: number; label: string });
            }}
            isSearchable={true}
          />
        </div>
      </span>
      <FormControlLabel
        style={{ color: 'black' }}
        value='チャート非表示'
        control={
          <Switch
            color='secondary'
            sx={{ width: 60, height: 40 }}
            checked={props.isChartDisabled}
            onChange={() => {
              props.setIsChartDisabled(!props.isChartDisabled);
            }}
          />
        }
        label='チャート非表示'
        labelPlacement='start'
      />
      <FormControlLabel
        style={{ marginRight: 10, color: 'black' }}
        value='横表示'
        control={
          <Switch
            color='secondary'
            sx={{ width: 60, height: 40 }}
            checked={props.isKanbanView}
            onChange={() => {
              props.setIsKanbanView(!props.isKanbanView);
            }}
          />
        }
        label='横表示'
        labelPlacement='start'
      />
      {isLogin(user) ? (
        <>
          <span style={{ marginRight: 20 }}>
            <AddCategoryModal
              isSumaho={props.isSumaho}
              cookies={{}} //TODO 正しい値をセットする
              removeCookie={props.removeCookie}
              portfolio={props.portfolio}
              setPortfolio={props.setPortfolio}
              selectableIssues={props.selectableIssues}
              setSelectableIssues={props.setSelectableIssues}
              setItems={props.setItems}
              setContainers={props.setContainers}
            />
          </span>
          <AddIssueModal
            isSumaho={props.isSumaho}
            cookies={{}} //TODO 正しい値をセットする
            removeCookie={props.removeCookie}
            portfolio={props.portfolio}
            setPortfolio={props.setPortfolio}
            selectableIssues={props.selectableIssues}
            setSelectableIssues={props.setSelectableIssues}
            setItems={props.setItems}
            setContainers={props.setContainers}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default OptionAreaTemplate;
