import { useState, FC, useContext } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { showToast, changeRdsStatus, isRdsStatusSTOPPED } from '../../Func';
import { RdsStatusEnum } from '../../types';
import { RdsStatusContext } from '../../provider/rdsStatusContext';
import { signIn } from './LoginFunc';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

interface ChangeRdsStatusModalProps {}

const ChangeRdsStatusModals: FC<{}> = (props: ChangeRdsStatusModalProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const { rdsStatus, setRdsStatus } = useContext(RdsStatusContext);

  return (
    <div>
      {isRdsStatusSTOPPED(rdsStatus) ? (
        <Button onClick={handleOpen} color='warning' variant='contained' style={{ marginLeft: 10 }}>
          RDS起動
        </Button>
      ) : (
        <Button color='info' variant='contained' style={{ marginLeft: 10 }}>
          RDSのステータス更新中です、しらばらくお待ちください
        </Button>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            RDS起動
          </Typography>
          <TextField
            type='text'
            onChange={e => setEmail(e.target.value)}
            value={email}
            label='mail address'
            margin='normal'
          />
          <TextField
            type='password'
            onChange={e => setPassword(e.target.value)}
            value={password}
            label='password'
            margin='normal'
          />
          <div>
            <Button
              color='secondary'
              variant='contained'
              onClick={async () => {
                // ログイン
                const loginResponse = await signIn(email, password);
                if (loginResponse.statusCode === 200) {
                  // ログイン成功時
                  // RDS起動リクエスト送信
                  await changeRdsStatus(loginResponse.accessToken, true);
                  // RDS起動ステータスContextを起動中に変更
                  setRdsStatus(RdsStatusEnum.STARTING);
                  showToast('RDS起動リクエストを送りました', 'success');
                  handleClose();
                } else {
                  // ログイン失敗時
                  showToast(loginResponse.message, 'error');
                }
              }}
            >
              Lets RDS Start
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ChangeRdsStatusModals;
