import { Chart as GoogleChart } from 'react-google-charts';
import { FC } from 'react';
import { CardStyleType, ChartType, DailyChartType, TabTypes } from '../../types';

interface ChartProps {
  isLoading: boolean;
  name: string;
  stock_code: number;
  url: string;
  chart: ChartType;
  cardStyle: CardStyleType;
  tabType: TabTypes;
  isChartDisabled: boolean;
}

const Chart: FC<{
  isLoading: boolean;
  name: string;
  stock_code: number;
  url: string;
  chart: ChartType;
  cardStyle: CardStyleType;
  tabType: TabTypes;
  isChartDisabled: boolean;
}> = (props: ChartProps) => {
  // チャート表示用のデータ整形
  const dailyCharts = props.chart.daily_charts.map((dailyChart: DailyChartType, index: number) => {
    // X軸の表示用に日付を整形 (例: 2021-09-01 00:00:00 -> 09/01)
    const displayDatetime = `${dailyChart.datetime.split(' ')[0].split('-')[1]}/${
      dailyChart.datetime.split(' ')[0].split('-')[2]
    }`;
    return [
      displayDatetime,
      dailyChart.low,
      dailyChart.open,
      dailyChart.close,
      dailyChart.high,
      dailyChart.average_moving_line_five_value,
      dailyChart.average_moving_line_twentyfive_value,
      dailyChart.average_moving_line_seventyfive_value
      // dailyChart.bollinger_bands_plus_two_alpha,
      // dailyChart.bollinger_bands_minus_two_alpha
    ];
  });
  // 最新100件のみ切り出し
  const displayIndex =
    dailyCharts.length <= props.cardStyle.dailyChartsLength
      ? 0
      : dailyCharts.length - props.cardStyle.dailyChartsLength;
  const displayChartsVal = [...dailyCharts.slice(displayIndex)];
  const displayCharts = [
    [
      '日付',
      '安-高, 始-終',
      'BBBB',
      'CCCC',
      'DDDDD',
      '5日平均',
      '25日平均',
      '75日平均'
      // 'ボリンジャーバンド+2σ',
      // 'ボリンジャーバンド-2σ'
    ],
    ...displayChartsVal
  ];
  // // 最初値と最大値を取得
  // const highs = displayChartsVal.map(dailyChart => dailyChart[4]) as number[];
  // const highestVal = Math.max(...highs);
  // const lows = displayChartsVal.map(dailyChart => dailyChart[1]) as number[];
  // const lowestVal = Math.max(...lows);

  // チャートの表示オプション
  const options = {
    legend: 'none',
    // backgroundColor: '#f1f8e9',
    backgroundColor: '#e9e9e9',
    hAxis: {
      showTextEvery: props.cardStyle.hAxisShowTextEvery // X軸の間隔
    },
    vAxis: {
      textPosition: 'out'
    },
    // vAxis: { maxValue: highestVal, minValue: lowestVal }, // あるとY軸の株価の上限・下限が勝手に大きく取られてしまうのでコメントアウト
    candlestick: {
      risingColor: { fill: '#FB325C', stroke: '#FB325C', strokeWidth: 1 },
      fallingColor: { fill: '#4242BB', stroke: '#4242BB', strokeWidth: 1 }
    },
    chartArea: {
      height: props.cardStyle.chartAreaHeight,
      width: props.cardStyle.chartAreaWidth
    },
    seriesType: 'candlesticks',
    series: {
      1: { color: '#F360FF', visibleInLegend: true, type: 'line', lineWidth: 2 }, // 5日平均移動線
      2: { color: '#2FE179', visibleInLegend: true, type: 'line', lineWidth: 2 }, // 25日平均移動線
      3: { color: '#FDCB01', visibleInLegend: true, type: 'line', lineWidth: 2 } // 75日平均移動線
      // 4: { color: 'gray', visibleInLegend: true, type: 'line', lineWidth: 1 }, // ボリンジャーバンド+2σ
      // 5: { color: 'gray', visibleInLegend: true, type: 'line', lineWidth: 1 } // ボリンジャーバンド-2σ
    }
  };

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        {!props.isChartDisabled ? (
          <GoogleChart
            chartType='ComboChart'
            width={props.cardStyle.googleChartWidth}
            height={props.cardStyle.googleChartHeight}
            data={displayCharts}
            options={options}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Chart;
