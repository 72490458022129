import { useState, FC, useContext } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import 'react-toastify/dist/ReactToastify.css';
import { LoginUserContext } from '../../provider/loginUserContext';
import { showToast, getPortfolio, addCategory } from '../../Func';
import { PortfolioType, IssueType, CategoryType } from '../../types';
import { CookieSetOptions } from 'universal-cookie';
import { Items } from '../dnd-kit/examples/Sortable/MultipleContainers';
import { UniqueIdentifier } from '@dnd-kit/core';
import TextField from '@mui/material/TextField';

interface AddCategoryModalProps {
  isSumaho: boolean;
  cookies: any;
  removeCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  portfolio: PortfolioType;
  setPortfolio: React.Dispatch<React.SetStateAction<PortfolioType>>;
  selectableIssues: IssueType[];
  setSelectableIssues: React.Dispatch<React.SetStateAction<IssueType[]>>;
  setItems: React.Dispatch<React.SetStateAction<Items>>;
  setContainers: React.Dispatch<React.SetStateAction<UniqueIdentifier[]>>;
}

const AddCategoryModal: FC<{
  isSumaho: boolean;
  cookies: any;
  removeCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  portfolio: PortfolioType;
  setPortfolio: React.Dispatch<React.SetStateAction<PortfolioType>>;
  selectableIssues: IssueType[];
  setSelectableIssues: React.Dispatch<React.SetStateAction<IssueType[]>>;
  setItems: React.Dispatch<React.SetStateAction<Items>>;
  setContainers: React.Dispatch<React.SetStateAction<UniqueIdentifier[]>>;
}> = (props: AddCategoryModalProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [categoryName, setCategoryName] = useState<string>('');

  // context
  const { user } = useContext(LoginUserContext);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: props.isSumaho ? 300 : 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
  };

  return (
    <>
      <Button onClick={handleOpen} color='primary' variant='contained'>
        カテゴリ追加
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            カテゴリ追加
          </Typography>

          <div style={{ width: props.isSumaho ? 200 : 600, marginTop: 20, marginBottom: 20 }}>
            <TextField
              id='outlined-basic'
              label='追加するカテゴリの名称'
              variant='outlined'
              value={categoryName}
              onChange={e => {
                setCategoryName(e.target.value);
              }}
              fullWidth
            />
          </div>
          <div>
            <Button
              color='secondary'
              variant='contained'
              onClick={async () => {
                const res = await addCategory(user.accessToken, categoryName);
                if (res.statusCode === 200) {
                  const addCategory = {
                    t_categories_id: res.body.tCategoriesId,
                    name: categoryName,
                    index: res.body.index,
                    stock_codes: []
                  };
                  const newCategories = [...props.portfolio.categories, addCategory];
                  const newPortfolio = { ...props.portfolio, categories: newCategories };
                  props.setPortfolio(newPortfolio);
                  // dnd-kitのContainerとItem更新
                  let itemsObj: Items = {};
                  newPortfolio.categories.forEach((c: CategoryType, index: number) => {
                    Object.assign(itemsObj, { [`${c.t_categories_id}`]: [...c.stock_codes] });
                  });
                  props.setItems(itemsObj);
                  const containerKeys = newPortfolio.categories.map(
                    c => c.t_categories_id as UniqueIdentifier
                  );
                  props.setContainers(containerKeys);
                  // モーダルの選択状態解除
                  setCategoryName('');
                  showToast('カテゴリーの追加に成功しました', 'success');
                  handleClose();
                } else {
                  if (res.body.message === 'Access Token has expired') {
                    // ポートフォリオ再取得
                    const repeatRes = await getPortfolio('', false);
                    // ポートフォリオのstate更新
                    props.setPortfolio(repeatRes.body.portfolio);
                    // トークン削除
                    props.removeCookie('accessToken', '');
                    // ログインの有効期限切れましたのトースト表示
                    showToast('ログインの有効期限が切れました。', 'warning');
                  } else {
                    showToast('カテゴリーの追加に失敗しました', 'error');
                  }
                }
              }}
            >
              追加
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AddCategoryModal;
