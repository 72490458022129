import { Amplify, Auth } from 'aws-amplify';
import { LoginResponseType } from '../../types';

// 使用するユーザープール、クライアントの ID を指定
Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
  }
});

export const signout = async (): Promise<LoginResponseType> => {
  try {
    const user = await Auth.signOut();
    console.log(user);
    return {
      statusCode: 200,
      message: 'ログアウトに成功しました',
      mailAddress: '',
      accessToken: ''
    };
  } catch (error) {
    console.log(error);
    return {
      statusCode: 400,
      message: 'ログアウトに失敗しました',
      mailAddress: '',
      accessToken: ''
    };
  }
};
