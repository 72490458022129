import { FC, useState } from 'react';
import Chart from '../chart/Chart';
import Volume from '../volume/Volume';
import { ChartType, CardStyleType, PortfolioType, IssueType, TabTypes } from '../../types';
import { useContext } from 'react';
import { LoginUserContext } from '../../provider/loginUserContext';
import { AllIssuesContext } from '../../provider/nekoContext';
import { isLogin, importIssue, getPortfolio, showToast } from '../../Func';
import { randomString } from '../../utils/randomString';
import Link from '@mui/material/Link';
import LaunchIcon from '@mui/icons-material/Launch';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import GetAppIcon from '@mui/icons-material/GetApp';
import { CookieSetOptions } from 'universal-cookie';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';

import styles from '../dnd-kit/components/Container/Container.module.scss';
import { Remove } from '../dnd-kit/components/Item/components/Remove';
import { setMarketDivisionsColor } from '../../utils/setMarketDivisionsColor';

const Issue: FC<{
  isLoading: boolean;
  name: string;
  stock_code: number;
  url: string;
  m_market_divisions_id: number;
  is_import: boolean;
  chart: ChartType;
  isSumaho: boolean;
  onRemove?(): void;
  removeCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  portfolio: PortfolioType;
  setPortfolio: React.Dispatch<React.SetStateAction<PortfolioType>>;
  isEditable: boolean;
  tabType: TabTypes;
  isChartDisabled: boolean;
  setSelectedStockCode: React.Dispatch<React.SetStateAction<number>>;
  setIsZoomModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props: {
  isLoading: boolean;
  name: string;
  stock_code: number;
  url: string;
  m_market_divisions_id: number;
  is_import: boolean;
  chart: ChartType;
  isSumaho: boolean;
  onRemove?(): void;
  removeCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  portfolio: PortfolioType;
  setPortfolio: React.Dispatch<React.SetStateAction<PortfolioType>>;
  isEditable: boolean;
  tabType: TabTypes;
  isChartDisabled: boolean;
  setSelectedStockCode: React.Dispatch<React.SetStateAction<number>>;
  setIsZoomModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    isLoading,
    name,
    stock_code,
    url,
    m_market_divisions_id,
    isSumaho,
    chart,
    isEditable,
    tabType,
    isChartDisabled
  } = props;
  // カードのスタイル指定(初期値)
  let cardStyle: CardStyleType = {
    cardWidth: 300,
    cardHeight: 250,
    hAxisShowTextEvery: 33,
    chartAreaHeight: '60%',
    chartAreaWidth: '70%',
    dailyChartsLength: 100,
    googleChartWidth: 250,
    googleChartHeight: 200,
    cardTitleAreaWidth: 180
  };
  if (isSumaho) {
    // スマホのとき
    if (!isChartDisabled) {
      // チャート表示
      cardStyle = {
        cardWidth: 300,
        cardHeight: 250,
        hAxisShowTextEvery: 33,
        chartAreaHeight: '60%',
        chartAreaWidth: '70%',
        dailyChartsLength: 100,
        googleChartWidth: 250,
        googleChartHeight: 200,
        cardTitleAreaWidth: 180
      };
    } else {
      // チャート非表示
      cardStyle = {
        cardWidth: 0, // 指定不要
        cardHeight: 0, // 指定不要
        hAxisShowTextEvery: 0, // つかっていないので適当
        chartAreaHeight: '0%', // つかっていないので適当
        chartAreaWidth: '0%', // つかっていないので適当
        dailyChartsLength: 0, // つかっていないので適当
        googleChartWidth: 0, // つかっていないので適当
        googleChartHeight: 0, // つかっていないので適当
        cardTitleAreaWidth: 180
      };
    }
  } else {
    // PC画面のとき
    if (!isChartDisabled) {
      // チャート表示
      cardStyle = {
        cardWidth: 1000,
        cardHeight: 250,
        hAxisShowTextEvery: 33,
        chartAreaHeight: '85%',
        chartAreaWidth: '90%',
        dailyChartsLength: 100,
        googleChartWidth: 800,
        googleChartHeight: 500,
        cardTitleAreaWidth: 700
      };
      // もとの大きめ
      // cardStyle = {
      //   cardWidth: 1000,
      //   cardHeight: 250,
      //   hAxisShowTextEvery: 7,
      //   chartAreaHeight: '85%',
      //   chartAreaWidth: '90%',
      //   dailyChartsLength: 50,
      //   googleChartWidth: 800,
      //   googleChartHeight: 500,
      //   cardTitleAreaWidth: 700
      // };
    } else {
      // チャート非表示
      cardStyle = {
        cardWidth: 0, // 指定不要
        cardHeight: 0, // 指定不要
        hAxisShowTextEvery: 0, // つかっていないので適当
        chartAreaHeight: '0%', // つかっていないので適当
        chartAreaWidth: '0%', // つかっていないので適当
        dailyChartsLength: 0, // つかっていないので適当
        googleChartWidth: 0, // つかっていないので適当
        googleChartHeight: 0, // つかっていないので適当
        cardTitleAreaWidth: 200
      };
    }
  }

  // マウスオーバー時にボタン関連表示
  // const [opacityVal, setOpacityVal] = useState<number>(isSumaho ? 1 : 0);
  const [opacityVal, setOpacityVal] = useState<number>(0);

  const { user } = useContext(LoginUserContext);
  const { allIssuesDic, setAllIssuesDic } = useContext(AllIssuesContext);

  // 銘柄区分による色分け
  const marketDivision = setMarketDivisionsColor(m_market_divisions_id);

  const importChart = async () => {
    // ローディングに切り替え
    const tempAllDic = {
      ...allIssuesDic,
      [`${stock_code}`]: { ...allIssuesDic[stock_code], isLoading: true }
    };
    setAllIssuesDic(tempAllDic);
    // yahoofinanceAPI2呼び出し用のUserAgentを3文字のランダム文字列で生成
    const userAgent = randomString(3);
    // 最新チャート取り込み
    const res = await importIssue(user.accessToken, stock_code, userAgent);
    console.log(res);
    if (res.statusCode === 200) {
      console.log(allIssuesDic);
      // 取り込みチャートを抽出
      const importIssue = res.body.issue_list.find(issue => issue.stock_code === stock_code) as IssueType;
      // 全チャートContextにセット
      const newAllIssyeDic = { ...allIssuesDic, [`${stock_code}`]: importIssue };
      setAllIssuesDic(newAllIssyeDic);
      showToast('最新チャートの取り込みに成功しました', 'success');
    } else {
      if (res.body.message === 'Access Token has expired') {
        // ポートフォリオ再取得
        const repeatRes = await getPortfolio('', false);
        // ポートフォリオのstate更新
        props.setPortfolio(repeatRes.body.portfolio);
        // トークン削除
        props.removeCookie('accessToken', '');
        // ログインの有効期限切れましたのトースト表示
        showToast('ログインの有効期限が切れました。', 'warning');
      } else {
        showToast('チャートの最新化に失敗しました', 'error');
      }
      // ローディング終了
      const tempAllDic = {
        ...allIssuesDic,
        [`${stock_code}`]: { ...allIssuesDic[stock_code], isLoading: false }
      };
      setAllIssuesDic(tempAllDic);
    }
  };

  return (
    <div>
      {isLoading ? (
        // クルクルときも要素なしだたスタイルのみ指定
        <div
          style={{
            float: isLogin(user) ? 'left' : 'none',
            textAlign: 'left',
            width: cardStyle.cardTitleAreaWidth,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        ></div>
      ) : (
        <div
          onMouseEnter={() => setOpacityVal(1)}
          // onMouseLeave={() => (isSumaho ? setOpacityVal(1) : setOpacityVal(0))}
          onMouseLeave={() => setOpacityVal(0)}
          style={{ display: 'flex' }}
        >
          <div
            style={{
              float: isLogin(user) ? 'left' : 'none',
              textAlign: 'left',
              width: cardStyle.cardTitleAreaWidth,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            <Avatar
              sx={{
                width: 25,
                height: 25,
                fontSize: 12,
                float: 'left',
                backgroundColor: marketDivision.marketDivisionsColor
              }}
            >
              {marketDivision.marketDivisionsName}
            </Avatar>
            {`${stock_code}：${name}`}
          </div>
          {/* 掴む所？？ */}
          <div style={{ display: isLogin(user) ? 'flex' : 'contents' }}>
            <ZoomInIcon
              color='secondary'
              style={{ fontSize: 30, cursor: 'pointer', display: 'inline' }}
              onClick={() => {
                // 銘柄コードをセットしてズームモーダルを開く
                props.setSelectedStockCode(stock_code);
                props.setIsZoomModalOpen(true);
              }}
            />
            <Link href={url} target='_blank' rel='noopener'>
              <LaunchIcon />
            </Link>
            {/* ログインユーザー、かつ編集可能なタブのときは個別銘柄の削除可能 */}
            {isLogin(user) && isEditable && (
              <Remove className={styles.Remove} onClick={props.onRemove} style={{ opacity: opacityVal }} /> // ここを変えればよい
            )}
            {/* ログインユーザー、かつ編集可能なタブのときは最新チャートの取り込み可能 */}
            {isLogin(user) && isEditable && (
              <GetAppIcon
                className={styles.Remove}
                onClick={importChart}
                style={{ opacity: opacityVal, cursor: 'pointer' }}
              />
            )}
          </div>
        </div>
      )}
      {isLoading ? (
        <CircularProgress color='success' size={200} />
      ) : (
        <div>
          <Chart
            isLoading={isLoading}
            name={name}
            stock_code={stock_code}
            url={url}
            chart={chart}
            cardStyle={cardStyle}
            tabType={tabType}
            isChartDisabled={isChartDisabled}
          />
          {!isChartDisabled && <Volume chart={chart} cardStyle={cardStyle} />}
        </div>
      )}
    </div>
  );
};

export default Issue;
