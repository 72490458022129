import axios from 'axios';
import {
  ImportIssueResponseType,
  GetIssuesResponseType,
  GetAllIssuesResponseType,
  GetPortfolioResponseType,
  GetUserBodyResponseType,
  GetUserResponseType,
  AddIssueInCategoryResponseType,
  AddCategoryResponseType,
  DeleteCategoryResponseType,
  UpdateCategoryResponseType,
  SortIssueInCategoryResponseType,
  ToastType,
  UserType,
  GetRdsStatusResponseType,
  RdsStatusEnum,
  ChangeRdsStatusResponseType,
  GetMasterResponseType
} from './types';
import { toast, ToastOptions } from 'react-toastify';

/**
 * RDSが起動済みかどうか判定
 * @param rdsStatus
 * @returns
 */
export const isRdsStatusAvailable = (rdsStatus: RdsStatusEnum): boolean => {
  return rdsStatus === RdsStatusEnum.AVAILABLE;
};
/**
 * RDSが停止完了かどうか判定
 * @param rdsStatus
 * @returns
 */
export const isRdsStatusSTOPPED = (rdsStatus: RdsStatusEnum): boolean => {
  return rdsStatus === RdsStatusEnum.STOPPED;
};

/**
 * ログイン状態を判定
 * @param user
 * @returns
 */
export const isLogin = (user: UserType): boolean => {
  if (
    user.accessToken === '' ||
    user.accessToken === undefined ||
    user.mailAddress === '' ||
    user.mailAddress === undefined
  ) {
    return false;
  }
  return true;
};

/**
 * DK判定
 * @param user
 * @returns
 */
export const isDK = (user: UserType): boolean => {
  let isDK = false;
  if (user.roles === undefined) return false;
  user.roles.forEach(role => {
    if (role.name === 'DK') {
      isDK = true;
    }
  });
  return isDK;
};

/**
 * ロール判定
 * @param user
 * @returns
 */
export const isImporterOrDK = (user: UserType): boolean => {
  let isImporterOrDK = false;
  user.roles.forEach(role => {
    if (role.name === 'Importer' || role.name === 'DK') {
      isImporterOrDK = true;
    }
  });
  return isImporterOrDK;
};

/**
 * クッキーからアクセストークンを取得
 * @param cookies クッキー
 * @returns
 */
export const getAccessToken = (cookies: any) => {
  return cookies['accessToken'] === undefined ? '' : cookies['accessToken'];
};

/**
 * トースト表示
 * @param message
 * @param toastType
 */
export const showToast = (message: string, toastType: ToastType) => {
  const toastOptions: ToastOptions = {
    position: 'bottom-right',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light'
  };
  if (toastType === 'error') {
    toast.error(message, toastOptions);
  }
  if (toastType === 'info') {
    toast.info(message, toastOptions);
  }
  if (toastType === 'success') {
    toast.success(message, toastOptions);
  }
  if (toastType === 'warning') {
    toast.warning(message, toastOptions);
  }
  if (toastType === 'dark') {
    toast.dark(message, toastOptions);
  }
};

// https://1l1wfxaou6.execute-api.ap-northeast-1.amazonaws.com/dev/add_category

/**
 * RDSのステータス取得
 * @returns
 */
export const getRdsStatus = async (): Promise<GetRdsStatusResponseType> => {
  const res = await axios.post<GetRdsStatusResponseType>(
    'https://1l1wfxaou6.execute-api.ap-northeast-1.amazonaws.com/dev/get_rds_status',
    {},
    {
      headers: {
        'X-Api-Key': process.env.REACT_APP_X_API_KEY
      }
    }
  );
  return res.data;
};

/**
 * RDS起動or停止
 * @param accessToken
 * @param isStart
 * @returns
 */
export const changeRdsStatus = async (
  accessToken: string,
  isStart: boolean
): Promise<ChangeRdsStatusResponseType> => {
  const res = await axios.post<ChangeRdsStatusResponseType>(
    'https://1l1wfxaou6.execute-api.ap-northeast-1.amazonaws.com/dev/change_rds_status',
    { accessToken, isStart },
    {
      headers: {
        'X-Api-Key': process.env.REACT_APP_X_API_KEY
      }
    }
  );
  console.log(res);
  return res.data;
};

/**
 * マスタ取得
 * @returns
 */
export const getMaster = async (): Promise<GetMasterResponseType> => {
  const res = await axios.post<GetMasterResponseType>(
    'https://1l1wfxaou6.execute-api.ap-northeast-1.amazonaws.com/dev/get_master',
    {},
    {
      headers: {
        'X-Api-Key': process.env.REACT_APP_X_API_KEY
      }
    }
  );
  console.log(res);
  return res.data;
};

/**
 * 最新のチャート取得
 * @param accessToken
 * @param stockCode
 * @returns
 */
export const importIssue = async (
  accessToken: string,
  stockCode: number,
  userAgent: string
): Promise<ImportIssueResponseType> => {
  const res = await axios.post<ImportIssueResponseType>(
    'https://1l1wfxaou6.execute-api.ap-northeast-1.amazonaws.com/dev/import_chart',
    { accessToken, stockCode, userAgent },
    {
      headers: {
        'X-Api-Key': process.env.REACT_APP_X_API_KEY
      }
    }
  );
  const resBodyIssueList = res.data.body.issue_list.map(issue => {
    return { ...issue, isLoading: false };
  });
  return { ...res.data, body: { ...res.data.body, issue_list: resBodyIssueList } };
};

/**
 * 全銘柄の取得(チャートは取得しない)
 * @returns
 */
export const getAllIssues = async () => {
  const res = await axios.post<GetAllIssuesResponseType>(
    'https://1l1wfxaou6.execute-api.ap-northeast-1.amazonaws.com/dev/get_all_issues',
    {},
    {
      headers: {
        'X-Api-Key': process.env.REACT_APP_X_API_KEY
      }
    }
  );
  return res.data;
};

/**
 * 銘柄単位の取得
 * @param stockCodeList
 * @returns
 */
export const getIssues = async (stockCodeList: string[]): Promise<GetIssuesResponseType> => {
  const res = await axios.post<GetIssuesResponseType>(
    'https://1l1wfxaou6.execute-api.ap-northeast-1.amazonaws.com/dev/get_issues',
    {
      stockCodeList
    },
    {
      headers: {
        'X-Api-Key': process.env.REACT_APP_X_API_KEY
      }
    }
  );
  const resBodyIssueList = res.data.body.response.map(issue => {
    return { ...issue, isLoading: false };
  });
  console.log(res);
  return { ...res.data, body: { ...res.data.body, response: resBodyIssueList } };
};

/**
 * ポートフォリオ取得
 * @param accessToken
 * @param isFarstTimeDisplay
 * @returns
 */
export const getPortfolio = async (accessToken: string, isFirstTimeDisplay: boolean) => {
  const res = await axios.post<GetPortfolioResponseType>(
    'https://1l1wfxaou6.execute-api.ap-northeast-1.amazonaws.com/dev/get_portfolio',
    {
      isFirstTimeDisplay,
      accessToken: accessToken
    },
    {
      headers: {
        'X-Api-Key': process.env.REACT_APP_X_API_KEY
      }
    }
  );
  console.log(res.data);
  return res.data;
};

/**
 * ログインユーザーの情報を取得
 * @param accessToken
 * @returns
 */
export const getUser = async (accessToken: string | undefined): Promise<GetUserBodyResponseType> => {
  // アクセストークンがない場合はundifinedか空文字が引数で渡ってくる
  if (accessToken === undefined || accessToken === '') {
    return {
      user_id: 0,
      mail_address: '',
      roles: [],
      message: 'アクセストークンがありません'
    };
  }
  const res = await axios.post<GetUserResponseType>(
    'https://1l1wfxaou6.execute-api.ap-northeast-1.amazonaws.com/dev/get_user',
    {
      accessToken: accessToken
    },
    {
      headers: {
        'X-Api-Key': process.env.REACT_APP_X_API_KEY
      }
    }
  );
  console.log(res.data);
  const response = {
    user_id: res.data.body.user_id,
    mail_address: res.data.body.mail_address,
    roles: res.data.body.roles,
    message: res.data.body.message
  };
  return response;
};

/**
 * カテゴリに銘柄を追加
 * @param accessToken
 * @param tCategoriesId
 * @param stockCode
 * @param isImport
 * @param userAgent
 * @returns
 */
export const addIssueInCategory = async (
  accessToken: string,
  tCategoriesId: number,
  stockCode: number,
  isImport: boolean,
  userAgent: string
) => {
  const res = await axios.post<AddIssueInCategoryResponseType>(
    'https://1l1wfxaou6.execute-api.ap-northeast-1.amazonaws.com/dev/add_issue_in_category',
    {
      accessToken,
      tCategoriesId,
      stockCode,
      isImport,
      userAgent
    },
    {
      headers: {
        'X-Api-Key': process.env.REACT_APP_X_API_KEY
      }
    }
  );
  console.log(res);
  const resBodyIssueList = res.data.body.issue_list.map(issue => {
    return { ...issue, isLoading: false };
  });
  console.log(res);
  return { ...res.data, body: { ...res.data.body, response: resBodyIssueList } };
};

/**
 * カテゴリ内の銘柄削除
 * @param accessToken
 * @param tCategoriesId
 * @param stockCode
 * @returns
 */
export const deleteIssueInCategory = async (
  accessToken: string,
  tCategoriesId: number,
  stockCode: number
) => {
  const res = await axios.post<AddIssueInCategoryResponseType>(
    'https://1l1wfxaou6.execute-api.ap-northeast-1.amazonaws.com/dev/delete_issue_in_category',
    {
      accessToken,
      tCategoriesId,
      stockCode
    },
    {
      headers: {
        'X-Api-Key': process.env.REACT_APP_X_API_KEY
      }
    }
  );
  console.log(res);
  return res.data;
};

/**
 * カテゴリ内の銘柄並び替え
 * @param accessToken アクセストークン
 * @param isCrossCategories カテゴリを跨いだ並び替えか
 * @param destinationTCategoriesId 並び替え先のカテゴリID
 * @param sourceTCategoriesId 並び替え元のカテゴリID
 * @param stockCode 銘柄コード
 * @param index 並び替えた銘柄の並び替え後のインデックス
 * @returns
 */
export const sortIssueInCategory = async (
  accessToken: string,
  isCrossCategories: boolean,
  destinationTCategoriesId: number,
  sourceTCategoriesId: number,
  stockCode: number,
  index: number
) => {
  const res = await axios.post<SortIssueInCategoryResponseType>(
    'https://1l1wfxaou6.execute-api.ap-northeast-1.amazonaws.com/dev/sort_issue_in_category',
    {
      accessToken,
      isCrossCategories,
      destinationTCategoriesId,
      sourceTCategoriesId,
      stockCode,
      index
    },
    {
      headers: {
        'X-Api-Key': process.env.REACT_APP_X_API_KEY
      }
    }
  );
  console.log(res);
  return res.data;
};

/**
 * カテゴリ追加
 * @param accessToken
 * @param categoryName
 * @returns
 */
export const addCategory = async (accessToken: string, categoryName: string) => {
  const res = await axios.post<AddCategoryResponseType>(
    'https://1l1wfxaou6.execute-api.ap-northeast-1.amazonaws.com/dev/add_category',
    {
      accessToken,
      categoryName
    },
    {
      headers: {
        'X-Api-Key': process.env.REACT_APP_X_API_KEY
      }
    }
  );
  console.log(res);
  return res.data;
};

/**
 * カテゴリ削除
 * @param accessToken
 * @param tCategoriesId 削除したいカテゴリのtCategoriesId
 * @returns
 */
export const deleteCategory = async (accessToken: string, tCategoriesId: number) => {
  const res = await axios.post<DeleteCategoryResponseType>(
    'https://1l1wfxaou6.execute-api.ap-northeast-1.amazonaws.com/dev/delete_category',
    {
      accessToken,
      tCategoriesId
    },
    {
      headers: {
        'X-Api-Key': process.env.REACT_APP_X_API_KEY
      }
    }
  );
  console.log(res);
  return res.data;
};

/**
 * カテゴリ名更新
 * @param accessToken
 * @param tCategoriesId 更新したいカテゴリのtCategoriesId
 * @param categoryName 更新したいカテゴリ名
 * @returns
 */
export const updateCategory = async (accessToken: string, tCategoriesId: number, categoryName: string) => {
  const res = await axios.post<UpdateCategoryResponseType>(
    'https://1l1wfxaou6.execute-api.ap-northeast-1.amazonaws.com/dev/update_category',
    {
      accessToken,
      tCategoriesId,
      categoryName
    },
    {
      headers: {
        'X-Api-Key': process.env.REACT_APP_X_API_KEY
      }
    }
  );
  console.log(res);
  return res.data;
};

/**
 * カテゴリの並び替え
 * @param accessToken
 * @param tCategoryIds
 * @returns
 */
export const sortCategory = async (accessToken: string, tCategoryIds: number[]) => {
  const res = await axios.post<SortIssueInCategoryResponseType>(
    'https://1l1wfxaou6.execute-api.ap-northeast-1.amazonaws.com/dev/sort_category',
    {
      accessToken,
      tCategories: tCategoryIds
    },
    {
      headers: {
        'X-Api-Key': process.env.REACT_APP_X_API_KEY
      }
    }
  );
  console.log(res);
  return res.data;
};
