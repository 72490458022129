import AppBarTemplate from '../template/AppBarTemplate';
import IssueAreaTemplate from '../template/IssueAreaTemplate';
import { ToastContainer } from 'react-toastify';
import { Backdrop, CircularProgress } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { FC, useEffect, useState, useContext } from 'react';
import { CookieSetOptions } from 'universal-cookie';
import { getPortfolio, isRdsStatusAvailable, showToast } from '../Func';
import { CategoryType, PortfolioType, IssueType } from '../types';
import { Items } from '../component/dnd-kit/examples/Sortable/MultipleContainers';
import { UniqueIdentifier } from '@dnd-kit/core';
import { AllIssuesContext } from '../provider/nekoContext';
import { GlobalLoadingContext } from '../provider/globalLoadingContext';
import { RdsStatusContext } from '../provider/rdsStatusContext';
import { MasterContext } from '../provider/masterContext';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import ZoomModal from '../component/modal/ZoomModal';

interface TopPageProps {
  cookies: any;
  setCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  removeCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
}

const TopPage: FC<{
  cookies: any;
  setCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  removeCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
}> = (props: TopPageProps) => {
  const { allIssuesDic } = useContext(AllIssuesContext);
  const { isGlobalLoading } = useContext(GlobalLoadingContext);
  const { rdsStatus } = useContext(RdsStatusContext);
  const { master } = useContext(MasterContext);

  const [selectableIssues, setSelectableIssues] = useState<IssueType[]>([]);

  // ポートフォリオのstate
  const [portfolio, setPortfolio] = useState<PortfolioType>({ user_id: 0, categories: [] });
  // コンポーネントのobjのstete
  const [items, setItems] = useState<Items>({});
  // コンポーネントのリストのstate
  const [containers, setContainers] = useState<UniqueIdentifier[]>([]);
  // チャート表示・非表示切り替えのstate
  const [isChartDisabled, setIsChartDisabled] = useState<boolean>(false);
  // カンバンView切り替えのstate
  const [isKanbanView, setIsKanbanView] = useState<boolean>(false);
  // 拡大モーダルの表示切り替えstate
  const [isZoomModalOpen, setIsZoomModalOpen] = useState<boolean>(false);
  // 拡大モーダルで選択されている銘柄のstate
  const [selectedStockCode, setSelectedStockCode] = useState<number>(0);

  // 【業種別】ポートフォリオのstate
  const [industryClassificationPortfolio, setIndustryClassificationPortfolio] = useState<PortfolioType>({
    user_id: 0,
    categories: []
  });
  // 【業種別】コンポーネントのobjのstete
  const [industryClassificationItems, setIndustryClassificationItems] = useState<Items>({});
  // 【業種別】コンポーネントのリストのstate
  const [industryClassificationContainers, setIndustryClassificationContainers] = useState<
    UniqueIdentifier[]
  >([]);

  // 1カテゴリの1行内に表示するカードの数
  const [columnCount, setColumnCount] = useState<{ value: number; label: string }>({
    value: 2,
    label: '2'
  });

  // 初回のみ株データ取得
  useEffect(() => {
    const getPortfolioEffect = async () => {
      // RDSのステータスが利用可能でない時
      if (!isRdsStatusAvailable(rdsStatus)) {
        return false;
      }
      // cookieからaccessToken取得
      const accessToken = props.cookies['accessToken'] === undefined ? '' : props.cookies['accessToken'];
      // ユーザーのポートフォリオ取得
      const res = await getPortfolio(accessToken, false);
      // もしトークン期限が切れている時
      if (res.body.message === 'Access Token has expired') {
        // ポートフォリオ再取得
        const repeatRes = await getPortfolio('', false);
        // トークン削除
        props.removeCookie('accessToken', '');
        // ログインの有効期限切れましたのトースト表示
        showToast('ログインの有効期限が切れました。', 'warning');
        return repeatRes.body;
      }
      return res.body;
    };
    getPortfolioEffect().then(getPortfolioResBody => {
      // RDSのステータスが利用可能でない時はここで処理終了
      if (getPortfolioResBody === false) return;

      // カテゴリ別表示用のstateにセット
      setPortfolio(getPortfolioResBody.portfolio);
      let itemsObj: Items = {};
      getPortfolioResBody.portfolio.categories.forEach((c: CategoryType, index: number) => {
        Object.assign(itemsObj, { [`${c.t_categories_id}`]: [...c.stock_codes] });
      });
      const containerKeys = getPortfolioResBody.portfolio.categories.map(
        c => c.t_categories_id as UniqueIdentifier
      );
      setItems(itemsObj);
      setContainers(containerKeys);

      // 業種別表示用のstateにセット
      const industryClassificationContainerKeys = master.industry_classifications.map(
        i => i.m_industry_classifications_id as UniqueIdentifier
      );
      setIndustryClassificationContainers(industryClassificationContainerKeys);
      // 業種区分が同じでかつインポートされている銘柄を抽出
      const masterCategories = master.industry_classifications.map((i, index) => {
        let targetStockCodes = [];
        for (const stockCode of Object.keys(allIssuesDic)) {
          if (
            allIssuesDic[stockCode].m_industry_classifications_id === i.m_industry_classifications_id &&
            allIssuesDic[stockCode].is_import
          ) {
            targetStockCodes.push(Number(stockCode));
          }
        }
        return {
          t_categories_id: i.m_industry_classifications_id,
          name: i.name,
          index: index + 1,
          stock_codes: targetStockCodes
        };
      });
      setIndustryClassificationPortfolio({
        user_id: getPortfolioResBody.portfolio.user_id,
        categories: masterCategories
      });
      // PortFolioのカテゴリに設定されている銘柄を抽出
      let industryClassificationItems = {};
      masterCategories.forEach(i => {
        Object.assign(industryClassificationItems, {
          [`${i.t_categories_id}`]: [...i.stock_codes]
        });
      });
      setIndustryClassificationItems(industryClassificationItems);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.keys(allIssuesDic).length,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    props.cookies['accessToken'],
    rdsStatus,
    master.industry_classifications.length
  ]);

  // まだカテゴリに設定されていなくて選択可能な銘柄を抽出
  useEffect(() => {
    let options: [IssueType] = [
      {
        name: '',
        stock_code: 0,
        url: '',
        is_import: false,
        m_market_divisions_id: 0,
        m_industry_classifications_id: 0,
        updated_at: '',
        isLoading: true,
        chart: {
          stock_code: '',
          daily_charts: []
        }
      }
    ];
    Object.keys(allIssuesDic).forEach((i, index) => {
      let addFlag = true;
      portfolio.categories.forEach((c, index) => {
        if (c.stock_codes.find(s => s === allIssuesDic[i].stock_code)) addFlag = false;
      });
      if (addFlag) {
        options.push(allIssuesDic[i]);
      }
      setSelectableIssues(options.slice(1, options.length - 1));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.keys(allIssuesDic).length, portfolio.categories.length]);

  // スマホ判定横幅Width
  const SUMAHO_WIDTH = 430;
  // 画面サイズの取得
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  };
  const [, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  // // タブ管理state
  // const [tabVal, setTabVal] = useState(0);

  // /**
  //  * タブのタグのメタ情報生成
  //  * @param index
  //  * @returns
  //  */
  // const a11yProps = (index: number) => {
  //   return {
  //     id: `simple-tab-${index}`,
  //     'aria-controls': `simple-tabpanel-${index}`
  //   };
  // };

  // /**
  //  * タブ切り替え
  //  * @param event
  //  * @param newValue
  //  */
  // const tabValChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setTabVal(newValue);
  // };

  // interface TabPanelProps {
  //   children?: React.ReactNode;
  //   index: number;
  //   value: number;
  // }
  // /**
  //  * タブの中身のコンポーネント生成
  //  * @param props
  //  * @returns
  //  */
  // const CustomTabPanel = (props: TabPanelProps) => {
  //   const { children, value, index, ...other } = props;

  //   return (
  //     <div
  //       role='tabpanel'
  //       hidden={value !== index}
  //       id={`simple-tabpanel-${index}`}
  //       aria-labelledby={`simple-tab-${index}`}
  //       // style={{ marginTop: 30, padding: 0 }}
  //       {...other}
  //     >
  //       {value === index && (
  //         <Box sx={{ paddingTop: 8 }}>
  //           <Typography>{children}</Typography>
  //         </Box>
  //       )}
  //     </div>
  //   );
  // };

  const [tabValue, setTabValue] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      {/* 背景＋クルクル */}
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={isGlobalLoading}>
        <CircularProgress color='inherit' size={200} />
      </Backdrop>
      <Box sx={{ typography: 'body1', overflow: 'scroll' }}>
        <TabContext value={tabValue}>
          <AppBarTemplate
            cookies={props.cookies}
            setCookie={props.setCookie}
            removeCookie={props.removeCookie}
            portfolio={portfolio}
            setPortfolio={setPortfolio}
            setItems={setItems}
            setContainers={setContainers}
            handleChange={handleChange}
            children={<></>}
            // OptionAreaProps
            isSumaho={getWindowDimensions().width < SUMAHO_WIDTH}
            selectableIssues={selectableIssues}
            setSelectableIssues={setSelectableIssues}
            columnCount={columnCount}
            setColumnCount={setColumnCount}
            isChartDisabled={isChartDisabled}
            setIsChartDisabled={setIsChartDisabled}
            isKanbanView={isKanbanView}
            setIsKanbanView={setIsKanbanView}
          />
          <TabPanel value='1' sx={{ marginTop: 10 }}>
            <IssueAreaTemplate
              isSumaho={getWindowDimensions().width < SUMAHO_WIDTH}
              cookies={props.cookies}
              setCookie={props.setCookie}
              removeCookie={props.removeCookie}
              items={items}
              setItems={setItems}
              containers={containers}
              setCotainers={setContainers}
              portfolio={portfolio}
              setPortfolio={setPortfolio}
              isEditable={true}
              tabType='categoryTabType'
              columnCount={columnCount}
              isChartDisabled={isChartDisabled}
              isKanbanView={isKanbanView}
              setSelectedStockCode={setSelectedStockCode}
              setIsZoomModalOpen={setIsZoomModalOpen}
            />
          </TabPanel>
          <TabPanel value='2' sx={{ marginTop: 10 }}>
            <IssueAreaTemplate
              isSumaho={getWindowDimensions().width < SUMAHO_WIDTH}
              cookies={props.cookies}
              setCookie={props.setCookie}
              removeCookie={props.removeCookie}
              items={industryClassificationItems}
              setItems={setIndustryClassificationItems}
              containers={industryClassificationContainers}
              setCotainers={setIndustryClassificationContainers}
              portfolio={industryClassificationPortfolio}
              setPortfolio={setIndustryClassificationPortfolio}
              isEditable={false}
              tabType='industryClassTabType'
              columnCount={columnCount}
              isChartDisabled={isChartDisabled}
              isKanbanView={isKanbanView}
              setSelectedStockCode={setSelectedStockCode}
              setIsZoomModalOpen={setIsZoomModalOpen}
            />
          </TabPanel>
        </TabContext>
      </Box>
      {/* 拡大モーダルは共通部分で定義 */}
      {selectedStockCode !== 0 && (
        <ZoomModal
          selectedStockCode={selectedStockCode}
          isZoomModalOpen={isZoomModalOpen}
          setIsZoomModalOpen={setIsZoomModalOpen}
        />
      )}
      {/* トーストは共通部分で定義 */}
      <ToastContainer />
    </div>
  );
};

export default TopPage;
