import { FC } from 'react';
import { CookieSetOptions } from 'universal-cookie';
import { rectSortingStrategy } from '@dnd-kit/sortable';
import { MultipleContainers, Items } from '../component/dnd-kit/examples/Sortable/MultipleContainers';
import { UniqueIdentifier } from '@dnd-kit/core';
import { PortfolioType, MultipleContainerStyleType, TabTypes } from '../types';

interface IssueAreaTemplateProps {
  isSumaho: boolean;
  cookies: any;
  setCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  removeCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  /** Key:t_categories_id, Value:[数値のstock_code] */
  items: Items;
  setItems: React.Dispatch<React.SetStateAction<Items>>;
  /** 数値のt_categories_idのリスト */
  containers: UniqueIdentifier[];
  setCotainers: React.Dispatch<React.SetStateAction<UniqueIdentifier[]>>;
  /**ポートフォリオ */
  portfolio: PortfolioType;
  setPortfolio: React.Dispatch<React.SetStateAction<PortfolioType>>;
  /**ログインしているときに編集可能か */
  isEditable: boolean;
  /**タブの種類 */
  tabType: TabTypes;
  /**1カテゴリ内に表示するカードの数 */
  columnCount: { value: number; label: string };
  /**チャートを表示するかどうか */
  isChartDisabled: boolean;
  /**カンバンビューかどうか */
  isKanbanView: boolean;
  /**拡大モーダルに設定する銘柄変更 */
  setSelectedStockCode: React.Dispatch<React.SetStateAction<number>>;
  /**拡大モーダルを開く */
  setIsZoomModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const IssueAreaTemplate: FC<{
  isSumaho: boolean;
  cookies: any;
  setCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  removeCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  items: Items;
  setItems: React.Dispatch<React.SetStateAction<Items>>;
  containers: UniqueIdentifier[];
  setCotainers: React.Dispatch<React.SetStateAction<UniqueIdentifier[]>>;
  portfolio: PortfolioType;
  setPortfolio: React.Dispatch<React.SetStateAction<PortfolioType>>;
  isEditable: boolean;
  tabType: TabTypes;
  columnCount: { value: number; label: string };
  isChartDisabled: boolean;
  isKanbanView: boolean;
  setSelectedStockCode: React.Dispatch<React.SetStateAction<number>>;
  setIsZoomModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props: IssueAreaTemplateProps) => {
  // MultipleContainerのスタイル指定(初期値)
  let multipleContainerStyle: MultipleContainerStyleType = {
    colomns: props.columnCount.value,
    cardWidth: 840,
    cardHeight: 600,
    cardMarginRight: 30,
    vertical: !props.isKanbanView
  };
  if (props.isSumaho) {
    // スマホのとき
    multipleContainerStyle = {
      colomns: props.columnCount.value,
      cardWidth: 300,
      cardHeight: 420,
      cardMarginRight: 30,
      vertical: !props.isKanbanView
    };
  } else {
    // PC画面のとき
    if (props.isKanbanView) {
      // カンバン表示
      if (props.isChartDisabled) {
        // チャート非表示
        multipleContainerStyle = {
          colomns: props.columnCount.value,
          cardWidth: 200,
          cardHeight: 60,
          cardMarginRight: 30,
          vertical: !props.isKanbanView
        };
      } else {
        // チャート表示
        multipleContainerStyle = {
          colomns: props.columnCount.value,
          cardWidth: 840,
          cardHeight: 700,
          cardMarginRight: 30,
          vertical: !props.isKanbanView
        };
      }
    } else {
      // 通常表示
      if (props.isChartDisabled) {
        // チャート非表示
        multipleContainerStyle = {
          colomns: props.columnCount.value,
          cardWidth: 840, // ここは何でもOK？？
          cardHeight: 60,
          cardMarginRight: 30,
          vertical: !props.isKanbanView
        };
      } else {
        // チャート表示
        multipleContainerStyle = {
          colomns: props.columnCount.value,
          cardWidth: 840,
          cardHeight: 700,
          cardMarginRight: 30,
          vertical: !props.isKanbanView
        };
      }
    }
  }
  return (
    <div style={{ marginTop: 10, marginLeft: 10, height: '100svh' }}>
      {/* <div>{getWindowDimensions().width}</div> */}
      <MultipleContainers
        columns={multipleContainerStyle.colomns}
        itemCount={5}
        strategy={rectSortingStrategy}
        items={props.items}
        setItems={props.setItems}
        containers={props.containers}
        setContainers={props.setCotainers}
        wrapperStyle={() => ({
          width: multipleContainerStyle.cardWidth,
          height: multipleContainerStyle.cardHeight,
          marginRight: multipleContainerStyle.cardMarginRight
        })}
        isSumaho={props.isSumaho}
        cookies={props.cookies}
        removeCookie={props.removeCookie}
        portfolio={props.portfolio}
        setPortfolio={props.setPortfolio}
        isEditable={props.isEditable}
        tabType={props.tabType}
        vertical={multipleContainerStyle.vertical}
        isChartDisabled={props.isChartDisabled}
        isKanbanView={props.isKanbanView}
        setSelectedStockCode={props.setSelectedStockCode}
        setIsZoomModalOpen={props.setIsZoomModalOpen}
      />
    </div>
  );
};

export default IssueAreaTemplate;
