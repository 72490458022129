import { Chart as GoogleChart } from 'react-google-charts';
import { FC } from 'react';
import { CardStyleType, ChartType, DailyChartType } from '../../types';

interface VolumeProps {
  chart: ChartType;
  cardStyle: CardStyleType;
}

const Volume: FC<{
  chart: ChartType;
  cardStyle: CardStyleType;
}> = (props: VolumeProps) => {
  // チャート用の日付と出来高のデータ整形
  const volumes = props.chart.daily_charts.map((dailyChart: DailyChartType, index: number) => {
    // X軸の表示用に日付を整形 (例: 2021-09-01 00:00:00 -> 09/01)
    const displayDatetime = `${dailyChart.datetime.split(' ')[0].split('-')[1]}/${
      dailyChart.datetime.split(' ')[0].split('-')[2]
    }`;
    return [displayDatetime, dailyChart.volume];
  });

  // 指定されているデータの数だけ切り出し
  const displayIndex =
    volumes.length <= props.cardStyle.dailyChartsLength
      ? 0
      : volumes.length - props.cardStyle.dailyChartsLength;

  // 指定された数のみのデータ
  const displayVolumesVal = [...volumes.slice(displayIndex)];

  // GoogleChartで描画するデータ本体
  const data = [['日付', '出来高'], ...displayVolumesVal];

  const options = {
    width: props.cardStyle.googleChartWidth,
    height: 110,
    legend: { position: 'none' },
    chartArea: {
      height: '70%',
      width: props.cardStyle.chartAreaWidth
    },
    hAxis: {
      showTextEvery: props.cardStyle.hAxisShowTextEvery // X軸の間隔
    },
    vAxis: {
      textPosition: 'out',
      format: 'short'
    }
  };

  return <GoogleChart chartType='ColumnChart' data={data} options={options} />;
};

export default Volume;
