import { MarketDivisionsEnum } from '../types';

/**
 * 市場区分名と色を判定する
 * @param m_market_divisions_id
 * @returns
 */
export const setMarketDivisionsColor = (
  m_market_divisions_id: number
): { marketDivisionsName: string; marketDivisionsColor: string } => {
  let marketDivisionsName = '';
  let marketDivisionsColor = '';
  switch (m_market_divisions_id) {
    case MarketDivisionsEnum.PR:
      marketDivisionsName = 'PR';
      marketDivisionsColor = 'gray';
      break;
    case MarketDivisionsEnum.RE:
      marketDivisionsName = 'RE';
      marketDivisionsColor = 'gray';
      break;
    case MarketDivisionsEnum.出資:
      marketDivisionsName = '出資';
      marketDivisionsColor = 'gray';
      break;
    case MarketDivisionsEnum.外G:
      marketDivisionsName = '外G';
      marketDivisionsColor = 'gray';
      break;
    case MarketDivisionsEnum.外P:
      marketDivisionsName = '外P';
      marketDivisionsColor = 'gray';
      break;
    case MarketDivisionsEnum.外S:
      marketDivisionsName = '外S';
      marketDivisionsColor = 'gray';
      break;
    case MarketDivisionsEnum.ET:
      marketDivisionsName = 'ET';
      marketDivisionsColor = 'gray';
      break;
    case MarketDivisionsEnum.東G:
      marketDivisionsName = '東G';
      marketDivisionsColor = 'red';
      break;
    case MarketDivisionsEnum.東P:
      marketDivisionsName = '東P';
      marketDivisionsColor = 'green';
      break;
    case MarketDivisionsEnum.東S:
      marketDivisionsName = '東S';
      marketDivisionsColor = '#ffb500';
      break;
  }
  return { marketDivisionsName, marketDivisionsColor };
};
