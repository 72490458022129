import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { createPortal, unstable_batchedUpdates } from 'react-dom';
import {
  CancelDrop,
  closestCenter,
  pointerWithin,
  rectIntersection,
  CollisionDetection,
  DndContext,
  DragOverlay,
  DropAnimation,
  getFirstCollision,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  Modifiers,
  useDroppable,
  UniqueIdentifier,
  useSensors,
  useSensor,
  MeasuringStrategy,
  KeyboardCoordinateGetter,
  defaultDropAnimationSideEffects
} from '@dnd-kit/core';
import {
  AnimateLayoutChanges,
  SortableContext,
  useSortable,
  arrayMove,
  defaultAnimateLayoutChanges,
  verticalListSortingStrategy,
  SortingStrategy,
  horizontalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { coordinateGetter as multipleContainersCoordinateGetter } from './multipleContainersKeyboardCoordinates';
import { Item, Container, ContainerProps } from '../../components';
import { CategoryType, PortfolioType, TabTypes } from '../../../../types';
import {
  sortCategory,
  sortIssueInCategory,
  deleteIssueInCategory,
  deleteCategory,
  showToast,
  isImporterOrDK
} from '../../../../Func';
import { CookieSetOptions } from 'universal-cookie';
import { LoginUserContext } from '../../../../provider/loginUserContext';
// export default {
//   title: 'Presets/Sortable/Multiple Containers'
// };

const animateLayoutChanges: AnimateLayoutChanges = args =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

/**
 * カテゴリ用のコンテナのコンポーネント
 * @param param0
 * @returns
 */
const DroppableContainer = ({
  isEditable,
  children,
  columns = 1,
  disabled,
  id,
  items,
  style,
  tabType,
  isChartDisabled,
  isKanbanView,
  ...props
}: ContainerProps & {
  disabled?: boolean;
  id: UniqueIdentifier;
  items: UniqueIdentifier[];
  style?: React.CSSProperties;
  tabType: TabTypes;
  isChartDisabled: boolean;
  isKanbanView: boolean;
}) => {
  const { active, attributes, isDragging, listeners, over, setNodeRef, transition, transform } = useSortable({
    id,
    data: {
      type: 'container',
      children: items
    },
    animateLayoutChanges
  });
  const isOverContainer = over
    ? (id === over.id && active?.data.current?.type !== 'container') || items.includes(over.id)
    : false;

  return (
    <Container
      ref={disabled ? undefined : setNodeRef}
      isEditable={isEditable}
      style={{
        ...style,
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined
      }}
      hover={isOverContainer}
      handleProps={{
        ...attributes,
        ...listeners
      }}
      columns={columns}
      tabType={tabType}
      isChartDisabled={isChartDisabled}
      isKanbanView={isKanbanView}
      {...props}
    >
      {children}
    </Container>
  );
};

/**
 * 【見る必要なし】Drop用のアニメーション
 */
const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5'
      }
    }
  })
};

export type Items = Record<UniqueIdentifier, UniqueIdentifier[]>;

interface Props {
  adjustScale?: boolean;
  cancelDrop?: CancelDrop;
  columns?: number;
  containerStyle?: React.CSSProperties;
  coordinateGetter?: KeyboardCoordinateGetter;
  getItemStyles?(args: {
    value: UniqueIdentifier;
    index: number;
    overIndex: number;
    isDragging: boolean;
    containerId: UniqueIdentifier;
    isSorting: boolean;
    isDragOverlay: boolean;
  }): React.CSSProperties;
  wrapperStyle?(args: { index: number }): React.CSSProperties;

  isSumaho: boolean;

  itemCount?: number;

  items: Items;
  setItems: React.Dispatch<React.SetStateAction<Items>>;

  containers: UniqueIdentifier[];
  setContainers: React.Dispatch<React.SetStateAction<UniqueIdentifier[]>>;

  cookies: any;
  removeCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;

  portfolio: PortfolioType;
  setPortfolio: React.Dispatch<React.SetStateAction<PortfolioType>>;

  isEditable: boolean;

  /**タブの種類 */
  tabType: TabTypes;

  isChartDisabled: boolean;

  isKanbanView: boolean;

  setSelectedStockCode: React.Dispatch<React.SetStateAction<number>>;

  setIsZoomModalOpen: React.Dispatch<React.SetStateAction<boolean>>;

  initialItems?: Items;
  handle?: boolean;
  renderItem?: any;
  strategy?: SortingStrategy;
  modifiers?: Modifiers;
  minimal?: boolean;
  trashable?: boolean;
  scrollable?: boolean;
  vertical?: boolean;
}

export const TRASH_ID = 'void';
const PLACEHOLDER_ID = 'placeholder';
// const empty: UniqueIdentifier[] = [];

/**
 * 一番の大元
 * @param param0
 * @returns
 */
export function MultipleContainers({
  adjustScale = false,
  itemCount = 3,
  cancelDrop,
  columns,
  handle = true, // ドラッグハンドルの有無
  items,
  setItems,
  containers, // カテゴリの名前のリスト
  setContainers,
  cookies,
  removeCookie,
  portfolio,
  setPortfolio,
  isEditable,
  tabType,
  isChartDisabled,
  isKanbanView,
  setSelectedStockCode,
  setIsZoomModalOpen,
  containerStyle,
  coordinateGetter = multipleContainersCoordinateGetter,
  getItemStyles = () => ({}),
  wrapperStyle = () => ({}),
  isSumaho,
  minimal = false,
  modifiers,
  renderItem,
  strategy = verticalListSortingStrategy,
  trashable = false,
  vertical = false,
  scrollable
}: Props) {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);
  const isSortingContainer = activeId ? containers.includes(activeId) : false;

  // カテゴリContainer跨ぎかどうかを判定
  const [isOverContainer, setIsOverContainer] = useState<boolean>(false);

  const { user } = useContext(LoginUserContext);

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    args => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(container => container.id in items)
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId != null) {
        if (overId === TRASH_ID) {
          // If the intersecting droppable is the trash, return early
          // Remove this if you're not using trashable functionality in your app
          return intersections;
        }

        if (overId in items) {
          const containerItems = items[overId];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                container => container.id !== overId && containerItems.includes(container.id)
              )
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items]
  );
  const [clonedItems, setClonedItems] = useState<Items | null>(null);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter
    })
  );
  const findContainer = (id: UniqueIdentifier) => {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find(key => items[key].includes(id));
  };

  const getIndex = (id: UniqueIdentifier) => {
    const container = findContainer(id);

    if (!container) {
      return -1;
    }

    const index = items[container].indexOf(id);

    return index;
  };

  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setItems(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  /**
   * ポートフォリオのCategoriesの新規作成
   * @param overContainer 移動先のカテゴリContainerのID
   * @param overIndex 移動先のカテゴリContainerのIndex
   * @param ItemId 銘柄ItemのID
   * @returns
   */
  const createNewCategories = (
    overContainer: number,
    overIndex: number,
    ItemId: number
  ): { newCategories: CategoryType[]; sourceTCategoriesId: number } => {
    let newCategories: CategoryType[] = [];
    let sourceTCategoriesId = 0;
    portfolio.categories.forEach(c => {
      let newStockCodes: number[] = [];
      if (c.t_categories_id === Number(overContainer)) {
        // 移動先のカテゴリContainerの時は銘柄コードを指定のIndexに追加
        newStockCodes = c.stock_codes.map(s => s);
        newStockCodes.splice(overIndex, 0, ItemId);
      } else {
        // それ以外の時
        if (c.stock_codes.includes(ItemId)) {
          // 移動元のカテゴリContainerの時は移動された銘柄以外でリスト生成
          c.stock_codes.forEach(s => {
            if (ItemId !== s) {
              newStockCodes.push(s);
            }
          });
          sourceTCategoriesId = c.t_categories_id;
        } else {
          // それ以外時は銘柄コードのリストの変更なし
          newStockCodes = c.stock_codes.map(s => s);
        }
      }
      newCategories.push({ ...c, stock_codes: newStockCodes });
    });
    return { newCategories, sourceTCategoriesId };
  };

  return (
    /**
     * DndContextのコンポーネントで囲まれた部分がドラッグ可能な要素になる
     * onDragStartのようなon〜系はドラッグアンドドロップした時に発火する関数の指定ができる
     */
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always
        }
      }}
      // 銘柄ItemとカテゴリContainerの両方のドラッグスタート時に発火
      onDragStart={({ active }) => {
        setActiveId(active.id);
        setClonedItems(items);
      }}
      // 銘柄Itemとカテゴリコンテナの両方で、銘柄ItemもしくはカテゴリContainerの要素を跨いだ時に発火
      // というか銘柄Itemとカテゴリコンテナを掴んだ瞬間にも発火する
      onDragOver={({ active, over }) => {
        const overId = over?.id;

        if (overId == null || overId === TRASH_ID || active.id in items) {
          return;
        }

        const overContainer = findContainer(overId);
        const activeContainer = findContainer(active.id);

        if (!overContainer || !activeContainer) {
          return;
        }

        if (activeContainer !== overContainer) {
          setIsOverContainer(true);
          setItems(items => {
            const activeItems = items[activeContainer];
            const overItems = items[overContainer];
            const overIndex = overItems.indexOf(overId);
            const activeIndex = activeItems.indexOf(active.id);

            let newIndex: number;

            if (overId in items) {
              newIndex = overItems.length + 1;
            } else {
              const isBelowOverItem =
                over &&
                active.rect.current.translated &&
                active.rect.current.translated.top > over.rect.top + over.rect.height;

              const modifier = isBelowOverItem ? 1 : 0;

              newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            }

            recentlyMovedToNewContainer.current = true;

            return {
              ...items,
              [activeContainer]: items[activeContainer].filter(item => item !== active.id),
              [overContainer]: [
                ...items[overContainer].slice(0, newIndex),
                items[activeContainer][activeIndex],
                ...items[overContainer].slice(newIndex, items[overContainer].length)
              ]
            };
          });
        }
      }}
      // 銘柄Itemとカテゴリコンテナの両方のドラッグ終了時に発火
      onDragEnd={async ({ active, over }) => {
        /**
         * カテゴリContainerの並び替えのAPI呼出し
         * @param tCategoryIds カテゴリContainerのtCategoriesIdのリスト
         * @returns
         */
        const sortContainer = async (tCategoryIds: number[]) => {
          const res = await sortCategory(accessToken, tCategoryIds);
          // もしトークン期限が切れている時
          if (res.body.message === 'Access Token has expired') {
            // トークン削除
            removeCookie('accessToken', '');
            // ログインの有効期限切れましたのトースト表示
            showToast('ログインの有効期限が切れました。', 'warning');
            // ここで処理終了
            return false;
          }
          if (res.statusCode === 500) {
            showToast('エラーが発生しました。', 'error');
            return false;
          }
          showToast('カテゴリーの並び替えに成功しました', 'success');
        };

        // カテゴリContainerの並び替え時に発火
        if (active.id in items && over?.id) {
          const activeIndex = containers.indexOf(active.id);
          const overIndex = containers.indexOf(over.id);
          // 並び替え後のカテゴリContainerのIDリスト
          const containerIds = arrayMove(containers, activeIndex, overIndex);
          // 並び替え実施
          const isSortableRole = isImporterOrDK(user);
          // ログインユーザー、かつ編集可能なタブのとき
          if (isSortableRole && isEditable) {
            // 非同期でずらして実行
            setTimeout(sortContainer, 1, containerIds);
          }
          const sortCategories = containerIds.map((tCategoriesId, index) => {
            const category = portfolio.categories.find(c => c.t_categories_id === Number(tCategoriesId));
            return { ...category, index: index + 1 };
          }) as CategoryType[];
          setPortfolio({ ...portfolio, categories: sortCategories });
          // カテゴリContainerの並び替え
          setContainers(containerIds);
        }

        const activeContainer = findContainer(active.id);

        if (!activeContainer) {
          setActiveId(null);
          return;
        }

        const overId = over?.id;

        if (overId == null) {
          setActiveId(null);
          return;
        }

        if (overId === TRASH_ID) {
          // 発火するタイミング不明、、、
          setItems(items => ({
            ...items,
            [activeContainer]: items[activeContainer].filter(id => id !== activeId)
          }));
          setActiveId(null);
          return;
        }

        if (overId === PLACEHOLDER_ID) {
          // 発火するタイミング不明、、、
          const newContainerId = getNextContainerId();

          unstable_batchedUpdates(() => {
            setContainers(containers => [...containers, newContainerId]);
            setItems(items => ({
              ...items,
              [activeContainer]: items[activeContainer].filter(id => id !== activeId),
              [newContainerId]: [active.id]
            }));
            setActiveId(null);
          });
          return;
        }

        const overContainer = findContainer(overId);

        // cookieからaccessToken取得
        const accessToken = cookies['accessToken'] === undefined ? '' : cookies['accessToken'];

        /**
         * 並び替えのAPI呼出し
         */
        const sortIssues = async (
          isOverContainer: boolean,
          destinationTCategoriesId: number,
          sourceTCategoriesId: number,
          stockCode: number,
          index: number
        ) => {
          const res = await sortIssueInCategory(
            accessToken,
            isOverContainer,
            destinationTCategoriesId,
            sourceTCategoriesId,
            stockCode,
            index
          );
          // もしトークン期限が切れている時
          if (res.body.message === 'Access Token has expired') {
            // トークン削除
            removeCookie('accessToken', '');
            // ログインの有効期限切れましたのトースト表示
            showToast('ログインの有効期限が切れました。', 'warning');
            // ここで処理終了
            return false;
          }
          if (res.statusCode === 500) {
            showToast('エラーが発生しました。', 'error');
            return false;
          }
          showToast('銘柄の並び替えに成功しました', 'success');
        };

        if (overContainer) {
          // 銘柄Itemの並び替えのタイミングで必ず発火(※銘柄Containerを跨いだor跨がないに関係なく発火)
          const activeIndex = items[activeContainer].indexOf(active.id);
          const overIndex = items[overContainer].indexOf(overId);

          // 銘柄Itemの並び替え、かつカテゴリContainer内で順番が変更された時に発火
          if (!(active.id in items && over?.id) && activeIndex !== overIndex && !isOverContainer) {
            const isSortableRole = isImporterOrDK(user);
            // ログインユーザー、かつ編集可能なタブのときは並び替え実施
            if (isSortableRole && isEditable) {
              // 非同期でずらして実行
              setTimeout(sortIssues, 1, false, Number(overContainer), 0, Number(active.id), overIndex + 1);
            }
            // 一番シンプルなパターン
            let newCategories: CategoryType[] = [];
            portfolio.categories.forEach(c => {
              let newStockCodes: number[] = [];
              if (c.t_categories_id === Number(overContainer)) {
                // 該当の銘柄コードが含まれている時は最新の並び順で更新
                newStockCodes = c.stock_codes.filter(s => Number(active.id) !== s);
                newStockCodes.splice(overIndex, 0, Number(active.id));
              } else {
                // それ以外の時は銘柄コードのリストの変更なし
                newStockCodes = c.stock_codes.map(s => s);
              }
              newCategories.push({ ...c, stock_codes: newStockCodes });
            });
            setPortfolio({ ...portfolio, categories: newCategories });
            setItems(items => ({
              ...items,
              [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex)
            }));
          }

          // 銘柄Itemの並び替え、かつカテゴリContainer跨ぎで順番が変更された時に発火
          if (!(active.id in items && over?.id) && activeIndex !== overIndex && isOverContainer) {
            const { newCategories, sourceTCategoriesId } = createNewCategories(
              Number(overContainer),
              overIndex,
              Number(active.id)
            );
            const isSortableRole = isImporterOrDK(user);
            // ログインユーザーかつ、編集可能なタブのときは並び替え実施
            if (isSortableRole && isEditable) {
              // 非同期でずらして実行
              setTimeout(
                sortIssues,
                1,
                true,
                Number(overContainer),
                sourceTCategoriesId,
                Number(active.id),
                overIndex + 1
              );
            }
            setPortfolio({ ...portfolio, categories: newCategories });
            setItems(items => ({
              ...items,
              [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex)
            }));
          }

          // 銘柄Itemの並び替えで、コンテナ跨ぎの順番の並び替えが通常では検知されないパターンの時に発火(※ドラッグした後に移動させずにドロップした時の挙動を考慮済み)
          if (!(active.id in items && over?.id) && activeIndex === overIndex && isOverContainer) {
            const { newCategories, sourceTCategoriesId } = createNewCategories(
              Number(overContainer),
              overIndex,
              Number(active.id)
            );
            const isSortableRole = isImporterOrDK(user);
            // ログインユーザーかつ、編集可能なタブのときは並び替え実施
            if (isSortableRole && isEditable) {
              // 非同期でずらして実行
              setTimeout(
                sortIssues,
                1,
                true,
                Number(overContainer),
                sourceTCategoriesId,
                Number(active.id),
                overIndex + 1
              );
            }
            setPortfolio({ ...portfolio, categories: newCategories });
            setItems(items => ({
              ...items,
              [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex)
            }));
          }
        }
        setIsOverContainer(false);
        setActiveId(null);
      }}
      cancelDrop={cancelDrop}
      onDragCancel={onDragCancel}
      modifiers={modifiers}
    >
      <div
        style={{
          display: 'inline-grid',
          boxSizing: 'border-box',
          padding: 0, // 1つのコンテナ単位のpadding
          gridAutoFlow: vertical ? 'row' : 'column'
        }}
      >
        <SortableContext
          items={[...containers, PLACEHOLDER_ID]} // カテゴリContainerのIDリスト
          strategy={vertical ? verticalListSortingStrategy : horizontalListSortingStrategy}
        >
          {containers.map(containerId => (
            <DroppableContainer
              isEditable={isEditable}
              cookies={cookies}
              removeCookie={removeCookie}
              key={containerId}
              id={containerId}
              // カテゴリ名
              label={
                minimal
                  ? undefined
                  : portfolio.categories.find(c => c.t_categories_id === Number(containerId))?.name
              }
              columns={columns}
              items={items[containerId]}
              scrollable={scrollable}
              style={containerStyle}
              tabType={tabType}
              isChartDisabled={isChartDisabled}
              isKanbanView={isKanbanView}
              unstyled={minimal}
              portfolio={portfolio}
              setPortfolio={setPortfolio}
              containerId={Number(containerId)}
              onRemove={() => handleRemove(containerId)}
            >
              <SortableContext
                items={items[containerId]} // 銘柄ItemのIDリスト
                strategy={strategy}
              >
                {items[containerId].map((value, index) => {
                  return (
                    <SortableItem
                      disabled={isSortingContainer}
                      key={value}
                      id={value}
                      index={index}
                      handle={handle}
                      style={getItemStyles}
                      wrapperStyle={wrapperStyle}
                      isSumaho={isSumaho}
                      renderItem={renderItem}
                      containerId={containerId}
                      getIndex={getIndex}
                      onRemove={() => handleItemRemove(value, containerId)}
                      removeCookie={removeCookie}
                      portfolio={portfolio}
                      setPortfolio={setPortfolio}
                      isEditable={isEditable}
                      tabType={tabType}
                      isChartDisabled={isChartDisabled}
                      setSelectedStockCode={setSelectedStockCode}
                      setIsZoomModalOpen={setIsZoomModalOpen}
                    />
                  );
                })}
              </SortableContext>
            </DroppableContainer>
          ))}
          {/* Add columnからのカテゴリ追加はしない */}
          {/* {minimal ? undefined : (
            <DroppableContainer
              id={PLACEHOLDER_ID}
              disabled={isSortingContainer}
              items={empty}
              onClick={handleAddColumn}
              portfolio={portfolio}
              setPortfolio={setPortfolio}
              containerId={}
              placeholder
            >
              + Add column
            </DroppableContainer>
          )} */}
        </SortableContext>
      </div>
      {/* これはドラッグしているときに要素をドロップする位置の予測場所を示してくれる */}
      {createPortal(
        <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
          {activeId
            ? containers.includes(activeId)
              ? renderContainerDragOverlay(activeId)
              : renderSortableItemDragOverlay(activeId)
            : null}
        </DragOverlay>,
        document.body
      )}
      {/* 使われていない */}
      {trashable && activeId && !containers.includes(activeId) ? <Trash id={TRASH_ID} /> : null}
    </DndContext>
  );

  /**
   * ドラッグによって掴まれているItemの描画
   * @param id Itemのid(銘柄コード)
   * @returns
   */
  function renderSortableItemDragOverlay(id: UniqueIdentifier) {
    return (
      <Item
        value={id}
        handle={handle}
        style={getItemStyles({
          containerId: findContainer(id) as UniqueIdentifier,
          overIndex: -1,
          index: getIndex(id),
          value: id,
          isSorting: true,
          isDragging: true,
          isDragOverlay: true
        })}
        color={getColor(id)}
        wrapperStyle={wrapperStyle({ index: 0 })}
        isSumaho={isSumaho}
        renderItem={renderItem}
        removeCookie={removeCookie}
        portfolio={portfolio}
        setPortfolio={setPortfolio}
        isEditable={isEditable}
        tabType={tabType}
        isChartDisabled={isChartDisabled}
        setSelectedStockCode={setSelectedStockCode}
        setIsZoomModalOpen={setIsZoomModalOpen}
        dragOverlay
      />
    );
  }

  /**
   * ドラッグしている最中の銘柄Containerの描画
   * @param containerId
   * @returns
   */
  function renderContainerDragOverlay(containerId: UniqueIdentifier) {
    const category = portfolio.categories.find(c => c.t_categories_id === Number(containerId));
    return (
      <Container
        isEditable={isEditable}
        cookies={cookies}
        removeCookie={removeCookie}
        label={category === undefined ? '' : category.name}
        columns={columns}
        style={{
          height: '100%'
        }}
        tabType={tabType}
        isChartDisabled={isChartDisabled}
        isKanbanView={isKanbanView}
        shadow
        unstyled={false}
        portfolio={portfolio}
        setPortfolio={setPortfolio}
        containerId={Number(containerId)}
      >
        {items[containerId].map((item, index) => (
          <Item
            key={item}
            value={item}
            handle={handle}
            style={getItemStyles({
              containerId,
              overIndex: -1,
              index: getIndex(item),
              value: item,
              isDragging: false,
              isSorting: false,
              isDragOverlay: false
            })}
            color={getColor(item)}
            wrapperStyle={wrapperStyle({ index })}
            isSumaho={isSumaho}
            renderItem={renderItem}
            removeCookie={removeCookie}
            portfolio={portfolio}
            setPortfolio={setPortfolio}
            isEditable={isEditable}
            tabType={tabType}
            isChartDisabled={isChartDisabled}
            setSelectedStockCode={setSelectedStockCode}
            setIsZoomModalOpen={setIsZoomModalOpen}
          />
        ))}
      </Container>
    );
  }

  /**
   * カテゴリContainerの削除
   * @param containerID
   */
  function handleRemove(containerID: UniqueIdentifier) {
    // カテゴリの削除API呼出し
    const accessToken = cookies['accessToken'] === undefined ? '' : cookies['accessToken'];
    const isDeletableRole = isImporterOrDK(user);
    // ログインユーザー、かつ編集可能なタブのときは削除実施
    if (isDeletableRole && isEditable) {
      // 非同期でずらして実行
      setTimeout(deleteContainer, 1, accessToken, Number(containerID));
    }
    // ポートフォリオのカテゴリから該当のカテゴリを削除
    let newCategories: CategoryType[] = [];
    let categoryIndex = 1;
    portfolio.categories.forEach(c => {
      if (c.t_categories_id !== Number(containerID)) {
        // 削除するカテゴリ以外のものでindexを最新化して再生成
        newCategories.push({ ...c, index: categoryIndex });
        categoryIndex++;
      }
    });
    setPortfolio({ ...portfolio, categories: newCategories });
    // カテゴリContaierの表示も最新化
    setContainers(containers => containers.filter(id => id !== containerID));
  }

  /**
   * カテゴリ削除のAPI呼び出し
   * @param accessToken
   * @param tCategoriesId
   * @returns
   */
  async function deleteContainer(accessToken: string, tCategoriesId: number) {
    const res = await deleteCategory(accessToken, tCategoriesId);
    // もしトークン期限が切れている時
    if (res.body.message === 'Access Token has expired') {
      // トークン削除
      removeCookie('accessToken', '');
      // ログインの有効期限切れましたのトースト表示
      showToast('ログインの有効期限が切れました。', 'warning');
      // ここで処理終了
      return false;
    }
    if (res.statusCode === 500) {
      showToast('エラーが発生しました。', 'error');
      return false;
    }
    showToast('カテゴリーの削除に成功しました', 'success');
  }

  /**
   * カテゴリの中の銘柄削除のAPI呼出し
   */
  async function deleteIssue(accessToken: string, tCategoriesId: number, stockCode: number) {
    const res = await deleteIssueInCategory(accessToken, tCategoriesId, stockCode);
    // もしトークン期限が切れている時
    if (res.body.message === 'Access Token has expired') {
      // トークン削除
      removeCookie('accessToken', '');
      // ログインの有効期限切れましたのトースト表示
      showToast('ログインの有効期限が切れました。', 'warning');
      // ここで処理終了
      return false;
    }
    if (res.statusCode === 500) {
      showToast('エラーが発生しました。', 'error');
      return false;
    }
    showToast('銘柄の削除に成功しました', 'success');
  }

  /**
   * 銘柄Itemの削除
   * @param itemId 銘柄コード
   * @param containerId tCategoriesId
   */
  function handleItemRemove(itemId: UniqueIdentifier, containerId: UniqueIdentifier) {
    // 銘柄の削除API呼出し
    const accessToken = cookies['accessToken'] === undefined ? '' : cookies['accessToken'];
    const isDeletableRole = isImporterOrDK(user);
    // ログインユーザー、かつ編集可能なタブのときは削除実施
    if (isDeletableRole && isEditable) {
      // 非同期でずらして実行
      setTimeout(deleteIssue, 1, accessToken, Number(containerId), Number(itemId));
    }

    // ポートフォリオのカテゴリから銘柄コードを削除
    let newCategories: CategoryType[] = [];
    portfolio.categories.forEach(c => {
      let newStockCodes: number[] = [];
      if (c.t_categories_id === Number(containerId)) {
        // 該当の銘柄コードが含まれている時はそれ以外でリスト生成
        newStockCodes = c.stock_codes.filter(s => Number(itemId) !== s);
      } else {
        // それ以外の時は銘柄コードのリストの変更なし
        newStockCodes = c.stock_codes.map(s => s);
      }
      newCategories.push({ ...c, stock_codes: newStockCodes });
    });
    setPortfolio({ ...portfolio, categories: newCategories });

    // 削除するカテゴリContainerのID
    const tCategoriesId = containers.find(id => id === containerId) as UniqueIdentifier;
    // 該当のカテゴリの新しい銘柄コードリスト生成
    const newItems = items[tCategoriesId].filter(id => id !== itemId);
    // tCategoriesIdをKeyにもつプロパティの銘柄コードリストを更新
    setItems(items => ({
      ...items,
      [tCategoriesId]: newItems
    }));
  }

  // /**
  //  * つかっていないのでコメントアウト
  //  */
  // function handleAddColumn() {
  //   const newContainerId = getNextContainerId();

  //   unstable_batchedUpdates(() => {
  //     setContainers(containers => [...containers, newContainerId]);
  //     setItems(items => ({
  //       ...items,
  //       [newContainerId]: []
  //     }));
  //   });
  // }

  function getNextContainerId() {
    const containerIds = Object.keys(items);
    const lastContainerId = containerIds[containerIds.length - 1];

    return String.fromCharCode(lastContainerId.charCodeAt(0) + 1);
  }
}

function getColor(id: UniqueIdentifier) {
  switch (String(id)[0]) {
    case 'A':
      return '#7193f1';
    case 'B':
      return '#ffda6c';
    case 'C':
      return '#00bcd4';
    case 'D':
      return '#ef769f';
  }

  return undefined;
}

/**
 * 【使われていない】削除
 * @param param0
 * @returns
 */
function Trash({ id }: { id: UniqueIdentifier }) {
  const { setNodeRef, isOver } = useDroppable({
    id
  });
  console.log('削除');
  return (
    <div
      ref={setNodeRef}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        left: '50%',
        marginLeft: -150,
        bottom: 20,
        width: 300,
        height: 60,
        borderRadius: 5,
        border: '1px solid',
        borderColor: isOver ? 'red' : '#DDD'
      }}
    >
      Drop here to delete
    </div>
  );
}

interface SortableItemProps {
  containerId: UniqueIdentifier;
  id: UniqueIdentifier;
  index: number;
  handle: boolean;
  disabled?: boolean;
  style(args: any): React.CSSProperties;
  getIndex(id: UniqueIdentifier): number;
  renderItem(): React.ReactElement;
  wrapperStyle({ index }: { index: number }): React.CSSProperties;
  isSumaho: boolean;
  onRemove(): void;
  removeCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  portfolio: PortfolioType;
  setPortfolio: React.Dispatch<React.SetStateAction<PortfolioType>>;
  isEditable: boolean;
  tabType: TabTypes;
  isChartDisabled: boolean;
  setSelectedStockCode: React.Dispatch<React.SetStateAction<number>>;
  setIsZoomModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function SortableItem({
  disabled,
  id,
  index,
  handle,
  renderItem,
  style,
  containerId,
  getIndex,
  wrapperStyle,
  isSumaho,
  onRemove,
  removeCookie,
  portfolio,
  setPortfolio,
  isEditable,
  tabType,
  isChartDisabled,
  setSelectedStockCode,
  setIsZoomModalOpen
}: SortableItemProps) {
  const {
    setNodeRef,
    setActivatorNodeRef,
    listeners,
    isDragging,
    isSorting,
    over,
    overIndex,
    transform,
    transition
  } = useSortable({
    id
  });
  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;

  return (
    <Item
      ref={disabled ? undefined : setNodeRef}
      value={id}
      dragging={isDragging}
      sorting={isSorting}
      handle={handle}
      handleProps={handle ? { ref: setActivatorNodeRef } : undefined}
      index={index}
      wrapperStyle={wrapperStyle({ index })}
      isSumaho={isSumaho}
      style={style({
        index,
        value: id,
        isDragging,
        isSorting,
        overIndex: over ? getIndex(over.id) : overIndex,
        containerId
      })}
      color={getColor(id)}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      renderItem={renderItem}
      removeCookie={removeCookie}
      portfolio={portfolio}
      setPortfolio={setPortfolio}
      isEditable={isEditable}
      onRemove={onRemove}
      tabType={tabType}
      isChartDisabled={isChartDisabled}
      setSelectedStockCode={setSelectedStockCode}
      setIsZoomModalOpen={setIsZoomModalOpen}
    />
  );
}

function useMountStatus() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  return isMounted;
}
