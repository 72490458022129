import { useState, FC, useContext } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import { signIn } from './LoginFunc';
import { signout } from './LogoutFunc';
import 'react-toastify/dist/ReactToastify.css';
import { CookieSetOptions } from 'universal-cookie';
import { LoginUserContext } from '../../provider/loginUserContext';
import { showToast, getUser, isLogin } from '../../Func';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

interface LoginModalProps {
  cookies: any;
  setCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  removeCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
}

const LoginModal: FC<{
  cookies: any;
  setCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  removeCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
}> = (props: LoginModalProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [, setUserName] = useState<string>('');

  // ログインユーザーcontext
  const { user, setUser } = useContext(LoginUserContext);

  return (
    <div>
      {!isLogin(user) ? (
        // 未ログイン
        <Button onClick={handleOpen} color='secondary' variant='contained'>
          Login
        </Button>
      ) : (
        // ログイン済み
        <>
          <span style={{ marginRight: 15 }}> {user.mailAddress}</span>
          <Button
            onClick={async () => {
              const res = await signout();
              if (res.statusCode === 200) {
                props.removeCookie('accessToken', '');
                setUser({ mailAddress: '', accessToken: '', roles: [] });
                showToast(res.message, 'success');
              } else {
                showToast(res.message, 'error');
              }
            }}
            color='warning'
            variant='contained'
          >
            Logout
          </Button>
        </>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Login
          </Typography>
          <TextField
            type='text'
            onChange={e => setEmail(e.target.value)}
            value={email}
            label='mail address'
            margin='normal'
          />
          <TextField
            type='password'
            onChange={e => setPassword(e.target.value)}
            value={password}
            label='password'
            margin='normal'
          />
          <div>
            <Button
              color='secondary'
              variant='contained'
              onClick={async () => {
                // ログイン
                const loginResponse = await signIn(email, password);
                if (loginResponse.statusCode === 200) {
                  // ログイン成功時
                  const res = await getUser(loginResponse.accessToken);
                  if (res.user_id !== 0) {
                    // ログインしている、かつ有効期限が残っているとき
                    setUser({
                      mailAddress: res.mail_address,
                      accessToken: loginResponse.accessToken,
                      roles: res.roles
                    });
                    props.setCookie('accessToken', loginResponse.accessToken);
                    showToast(loginResponse.message, 'success');
                    handleClose();
                  } else {
                    // ログインしているがユーザー情報が取れないとき
                    // ここは通ることがない想定
                    showToast(loginResponse.message, 'error');
                    setUser({ mailAddress: '', accessToken: '', roles: [] });
                    props.removeCookie('accessToken', '');
                  }
                } else {
                  // ログイン失敗時
                  showToast(loginResponse.message, 'error');
                  setUser({ mailAddress: '', accessToken: '', roles: [] });
                  props.removeCookie('accessToken', '');
                }
                setUserName(loginResponse.mailAddress);
              }}
            >
              Lets Login
            </Button>
          </div>
          {/* </Typography> */}
        </Box>
      </Modal>
    </div>
  );
};

export default LoginModal;
