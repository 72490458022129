import { useState, FC, useContext } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import 'react-toastify/dist/ReactToastify.css';
import { LoginUserContext } from '../../provider/loginUserContext';
import { AllIssuesContext } from '../../provider/nekoContext';
import { showToast, addIssueInCategory, getPortfolio } from '../../Func';
import { PortfolioType, IssueType, CategoryType } from '../../types';
import Select from 'react-select';
import { CookieSetOptions } from 'universal-cookie';
import { Items } from '../dnd-kit/examples/Sortable/MultipleContainers';
import { UniqueIdentifier } from '@dnd-kit/core';
import { randomString } from '../../utils/randomString';
import { MasterContext } from '../../provider/masterContext';
import { GlobalLoadingContext } from '../../provider/globalLoadingContext';

interface AddIssueModalProps {
  isSumaho: boolean;
  cookies: any;
  // setCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  removeCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  portfolio: PortfolioType;
  setPortfolio: React.Dispatch<React.SetStateAction<PortfolioType>>;
  selectableIssues: IssueType[];
  setSelectableIssues: React.Dispatch<React.SetStateAction<IssueType[]>>;
  setItems: React.Dispatch<React.SetStateAction<Items>>;
  setContainers: React.Dispatch<React.SetStateAction<UniqueIdentifier[]>>;
}

const AddIssueModal: FC<{
  isSumaho: boolean;
  cookies: any;
  // setCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  removeCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  portfolio: PortfolioType;
  setPortfolio: React.Dispatch<React.SetStateAction<PortfolioType>>;
  selectableIssues: IssueType[];
  setSelectableIssues: React.Dispatch<React.SetStateAction<IssueType[]>>;
  setItems: React.Dispatch<React.SetStateAction<Items>>;
  setContainers: React.Dispatch<React.SetStateAction<UniqueIdentifier[]>>;
}> = (props: AddIssueModalProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [tCategoriesId, setTCategoriesId] = useState<{ value: string; label: string }>({
    value: '',
    label: ''
  });
  const [stockCode, setStockCode] = useState<{ value: string; label: string }>({
    value: '',
    label: ''
  });
  const [industryClassificationId, setIndustryClassificationId] = useState<{ value: number; label: string }>({
    value: 0,
    label: ''
  });

  // context
  const { user } = useContext(LoginUserContext);
  const { allIssuesDic } = useContext(AllIssuesContext);
  const { master } = useContext(MasterContext);
  const { setIsGlobalLoading } = useContext(GlobalLoadingContext);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: props.isSumaho ? 300 : 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
  };

  return (
    <>
      <Button onClick={handleOpen} color='primary' variant='contained'>
        銘柄追加
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            銘柄追加
          </Typography>
          {/* カテゴリ */}
          <div style={{ width: props.isSumaho ? 200 : 600, marginTop: 20, marginBottom: 20 }}>
            <span>追加先カテゴリ</span>
            <Select
              options={props.portfolio.categories.map(c => {
                return {
                  value: String(c.t_categories_id),
                  label: c.name
                };
              })}
              value={tCategoriesId}
              onChange={e => {
                setTCategoriesId(e as { value: string; label: string });
              }}
              isSearchable={true}
            />
          </div>
          {/* 業種区別 */}
          <div style={{ width: props.isSumaho ? 200 : 600, marginTop: 20, marginBottom: 20 }}>
            <span>業種区別</span>
            <Select
              options={master.industry_classifications.map(i => {
                return {
                  value: i.m_industry_classifications_id,
                  label: i.name
                };
              })}
              value={industryClassificationId}
              onChange={e => {
                // 業種区分設定
                setIndustryClassificationId(e as { value: number; label: string });
                // 業種区分設定したタイミングで銘柄の選択状態を解除
                setStockCode({ value: '', label: '' });
              }}
              isSearchable={true}
            />
          </div>
          {/* 追加する銘柄 */}
          <div style={{ width: props.isSumaho ? 200 : 600, marginBottom: 20 }}>
            <span>追加する銘柄</span>
            <Select
              options={props.selectableIssues
                .filter(i => {
                  // 業種区分が未選択のときは全銘柄表示
                  if (industryClassificationId.value === 0) {
                    return true;
                  } else {
                    // 業種区分が選択されているときは業種区分に該当する銘柄のみ表示
                    return i.m_industry_classifications_id === industryClassificationId.value;
                  }
                })
                .map(i => {
                  return {
                    value: String(i.stock_code),
                    label: `${i.stock_code}T：${i.name}`
                  };
                })}
              value={stockCode}
              onChange={e => {
                setStockCode(e as { value: string; label: string });
              }}
              isSearchable={true}
            />
          </div>
          {/* UserAgent */}
          {/* <div style={{ width: props.isSumaho ? 200 : 600, marginBottom: 20 }}>
            <span>UserAgent</span>
            <TextField
              id='userAgent'
              // label='追加するカテゴリの名称'
              variant='outlined'
              value={userAgent}
              onChange={e => {
                setUserAgent(e.target.value);
              }}
              fullWidth
            />
          </div> */}
          <div>
            <Button
              color='secondary'
              variant='contained'
              onClick={async () => {
                // 追加先カテゴリと銘柄が選択されていないときは処理ストップ
                if (tCategoriesId.value === '' || stockCode.value === '') {
                  alert('追加先カテゴリと銘柄を選択してください');
                  return false;
                }
                // モーダル非表示
                handleClose();
                // グローバルクルクルあり
                setIsGlobalLoading(true);
                // yahoofinanceAPI2呼び出し用のUserAgentを3文字のランダム文字列で生成
                const userAgent = randomString(3);
                // 選択されている銘柄
                const issue = props.selectableIssues.find(
                  i => i.stock_code === Number(stockCode.value)
                ) as IssueType;
                const res = await addIssueInCategory(
                  user.accessToken,
                  Number(tCategoriesId.value),
                  Number(stockCode.value),
                  issue.is_import,
                  userAgent
                );
                if (res.statusCode === 200) {
                  // とりこみがまだであれば最新のチャートを全銘柄Dicにセット
                  if (!issue.is_import) {
                    allIssuesDic[issue.stock_code] = res.body.issue_list[0];
                  }
                  // ポートフォリオのstate更新
                  const targetCategory = props.portfolio.categories.find(
                    c => c.t_categories_id === Number(tCategoriesId.value)
                  ) as CategoryType;
                  targetCategory.stock_codes.push(Number(stockCode.value));
                  const newCategories = props.portfolio.categories.map(c => {
                    if (c.t_categories_id === Number(tCategoriesId.value)) {
                      return targetCategory;
                    } else {
                      return c;
                    }
                  });
                  const newPortfolio = { ...props.portfolio, categories: newCategories };
                  props.setPortfolio(newPortfolio);
                  // dnd-kitのContainerとItem更新
                  let itemsObj: Items = {};
                  newPortfolio.categories.forEach((c: CategoryType, index: number) => {
                    Object.assign(itemsObj, { [`${c.t_categories_id}`]: [...c.stock_codes] });
                  });
                  props.setItems(itemsObj);
                  const containerKeys = newPortfolio.categories.map(
                    c => c.t_categories_id as UniqueIdentifier
                  );
                  props.setContainers(containerKeys);
                  // モーダルの選択状態解除
                  setTCategoriesId({ value: '', label: '' });
                  setStockCode({ value: '', label: '' });
                  setIndustryClassificationId({ value: 0, label: '' });
                  showToast('銘柄の追加に成功しました', 'success');
                  // handleClose();
                } else {
                  if (res.body.message === 'Access Token has expired') {
                    // ポートフォリオ再取得
                    const repeatRes = await getPortfolio('', false);
                    // ポートフォリオのstate更新
                    props.setPortfolio(repeatRes.body.portfolio);
                    // トークン削除
                    props.removeCookie('accessToken', '');
                    // ログインの有効期限切れましたのトースト表示
                    showToast('ログインの有効期限が切れました。', 'warning');
                  } else {
                    showToast('銘柄の追加に失敗しました', 'error');
                  }
                }
                // グローバルクルクル非表示
                setIsGlobalLoading(false);
              }}
            >
              追加
            </Button>
          </div>
          {/* </Typography> */}
        </Box>
      </Modal>
    </>
  );
};

export default AddIssueModal;
