import React, { useEffect, useContext } from 'react';
import classNames from 'classnames';
import type { DraggableSyntheticListeners } from '@dnd-kit/core';
import type { Transform } from '@dnd-kit/utilities';

import { Handle } from './components';

import styles from './Item.module.scss';

import Issue from '../../../issue/Issue';
import { AllIssuesContext } from '../../../../provider/nekoContext';
import { PortfolioType, TabTypes } from '../../../../types';
import { CookieSetOptions } from 'universal-cookie';

export interface Props {
  dragOverlay?: boolean;
  color?: string;
  disabled?: boolean;
  dragging?: boolean;
  handle?: boolean;
  handleProps?: any;
  height?: number;
  index?: number;
  fadeIn?: boolean;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  sorting?: boolean;
  style?: React.CSSProperties;
  transition?: string | null;
  wrapperStyle?: React.CSSProperties;
  isSumaho: boolean;
  value: React.ReactNode;
  onRemove?(): void;
  removeCookie: (name: 'accessToken', value: any, options?: CookieSetOptions | undefined) => void;
  portfolio: PortfolioType;
  setPortfolio: React.Dispatch<React.SetStateAction<PortfolioType>>;
  isEditable: boolean;
  tabType: TabTypes;
  isChartDisabled: boolean;
  setSelectedStockCode: React.Dispatch<React.SetStateAction<number>>;
  setIsZoomModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  renderItem?(args: {
    dragOverlay: boolean;
    dragging: boolean;
    sorting: boolean;
    index: number | undefined;
    fadeIn: boolean;
    listeners: DraggableSyntheticListeners;
    ref: React.Ref<HTMLElement>;
    style: React.CSSProperties | undefined;
    transform: Props['transform'];
    transition: Props['transition'];
    value: Props['value'];
  }): React.ReactElement;
}

/**
 * 銘柄単位のItemカードのコンポーネント
 */
export const Item = React.memo(
  React.forwardRef<HTMLLIElement, Props>(
    (
      {
        color,
        dragOverlay, // 掴まれた瞬間のみtrue
        dragging,
        disabled,
        fadeIn,
        handle,
        handleProps,
        height,
        index,
        listeners,
        onRemove,
        removeCookie,
        portfolio,
        setPortfolio,
        isEditable,
        tabType,
        isChartDisabled,
        setSelectedStockCode,
        setIsZoomModalOpen,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        value, // Item識別用のID、ここでは銘柄コード
        wrapperStyle,
        isSumaho,
        ...props
      },
      ref
    ) => {
      // ドラッグによって掴まれた瞬間を検知
      useEffect(() => {
        // 掴まれた瞬間以外は何もしない
        if (!dragOverlay) {
          return;
        }
        document.body.style.cursor = 'grabbing';
        return () => {
          document.body.style.cursor = '';
        };
      }, [dragOverlay]);

      const test = {
        stock_code: 'test',
        daily_charts: [
          {
            average_moving_line_five_value: 2481.4,
            average_moving_line_seventyfive_value: 2481.4,
            average_moving_line_twentyfive_value: 2498.8,
            bollinger_bands_minus_two_alpha: 2419.4,
            bollinger_bands_plus_two_alpha: 2578.3,
            close: 2608.5,
            datetime: '2023-11-01 00:00:00',
            high: 2612,
            low: 2555,
            open: 2563.5,
            volume: 71320
          }
        ]
      };

      // 全銘柄Context
      const { allIssuesDic } = useContext(AllIssuesContext);
      const isLoading =
        value !== undefined && allIssuesDic.hasOwnProperty(value as string)
          ? allIssuesDic[value as string].isLoading
          : true;
      const name =
        value !== undefined && allIssuesDic.hasOwnProperty(value as string)
          ? allIssuesDic[value as string].name
          : '';
      const stock_code =
        value !== undefined && allIssuesDic.hasOwnProperty(value as string)
          ? allIssuesDic[value as string].stock_code
          : 0;
      const url =
        value !== undefined && allIssuesDic.hasOwnProperty(value as string)
          ? allIssuesDic[value as string].url
          : '';
      const m_market_divisions_id =
        value !== undefined && allIssuesDic.hasOwnProperty(value as string)
          ? allIssuesDic[value as string].m_market_divisions_id
          : 0;
      const is_import =
        value !== undefined && allIssuesDic.hasOwnProperty(value as string)
          ? allIssuesDic[value as string].is_import
          : false;
      const chart =
        value !== undefined && allIssuesDic.hasOwnProperty(value as string)
          ? allIssuesDic[value as string].chart
          : test;

      return renderItem ? (
        renderItem({
          dragOverlay: Boolean(dragOverlay),
          dragging: Boolean(dragging),
          sorting: Boolean(sorting),
          index,
          fadeIn: Boolean(fadeIn),
          listeners,
          ref,
          style,
          transform,
          transition,
          value
        })
      ) : (
        <li
          className={classNames(
            styles.Wrapper,
            fadeIn && styles.fadeIn,
            sorting && styles.sorting,
            dragOverlay && styles.dragOverlay
          )}
          style={
            {
              ...wrapperStyle,
              transition: [transition, wrapperStyle?.transition].filter(Boolean).join(', '),
              '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
              '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
              '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
              '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
              '--index': index,
              '--color': color,
              // カンバンボードのときは幅を広げる
              width: tabType === 'categoryCanbanTabType' ? '330px' : undefined
            } as React.CSSProperties
          }
          ref={ref}
        >
          <div
            className={classNames(
              styles.Item,
              dragging && styles.dragging,
              handle && styles.withHandle,
              dragOverlay && styles.dragOverlay,
              disabled && styles.disabled,
              color && styles.color
            )}
            style={style}
            data-cypress='draggable-item'
            {...(!handle ? listeners : undefined)}
            {...props}
            tabIndex={!handle ? 0 : undefined}
          >
            {/* ここが描画されている本体 */}
            <Issue
              isLoading={isLoading}
              name={name}
              stock_code={stock_code}
              url={url}
              m_market_divisions_id={m_market_divisions_id}
              is_import={is_import}
              chart={chart}
              isSumaho={isSumaho}
              onRemove={onRemove}
              removeCookie={removeCookie}
              portfolio={portfolio}
              setPortfolio={setPortfolio}
              isEditable={isEditable}
              tabType={tabType}
              isChartDisabled={isChartDisabled}
              setSelectedStockCode={setSelectedStockCode}
              setIsZoomModalOpen={setIsZoomModalOpen}
            />

            <span className={styles.Actions} style={{}}>
              {/* {onRemove ? <Remove className={styles.Remove} onClick={onRemove} /> : null} */}
              {handle ? <Handle {...handleProps} {...listeners} /> : null}
            </span>
          </div>
        </li>
      );
    }
  )
);
